import {Injectable} from '@angular/core';
import {FilterItem} from '../models/filter-item';
import {WeekDays} from '../enums/week-days.enum';
import {monthsEnum} from '../enums/months.enum';
import {Province} from '../enums/province.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private deleteSVG = '/assets/images/trash.svg';

  get DeleteSVG(): string {
    return this.deleteSVG;
  }

  get weekday(): FilterItem[] {
    return this.$weekday;
  }

  get roleList(): string[] {
    return this.$roleList;
  }

  get province(): FilterItem[] {
    return this.$province;
  }

  constructor() {
  }

  private $roleList: string[] = [
    'Employee',
    'Hr',
    'Ceo',
    'Admin'
  ];
  private $province: FilterItem[] = [
    {name: 'تهران', value: Province.Tehran},
    {name: 'البرز', value: Province.Alborz},
    {name: 'قم', value: Province.Qom},

  ];
  private $weekday: FilterItem[] = [
    {name: 'شنبه', value: WeekDays.Saturday},
    {name: 'یکشنبه', value: WeekDays.Sunday},
    {name: 'دوشنبه', value: WeekDays.Monday},
    {name: 'سه شنبه', value: WeekDays.Thursday},
    {name: 'چهارشنبه', value: WeekDays.Wednesday},
    {name: 'پنجشنبه', value: WeekDays.Tuesday},
    {name: 'جمعه', value: WeekDays.Friday},
  ];

  get monthsOfYear(): FilterItem[] {
    return this.$monthsOfYear;
  }

  private $monthsOfYear: FilterItem[] = [
    {name: 'فروردين', value: monthsEnum.Farvardin},
    {name: 'ارديبهشت', value: monthsEnum.Ordibehesht},
    {name: 'خرداد', value: monthsEnum.Khordad},
    {name: 'تير', value: monthsEnum.Tir},
    {name: 'مرداد', value: monthsEnum.Mordad},
    {name: 'شهريور', value: monthsEnum.Shahrivar},
    {name: 'مهر', value: monthsEnum.Mehr},
    {name: 'آبان', value: monthsEnum.Aban},
    {name: 'آذر', value: monthsEnum.Azar},
    {name: 'دی', value: monthsEnum.Dey},
    {name: 'بهمن', value: monthsEnum.Bahman},
    {name: 'اسفند', value: monthsEnum.Esfand},
  ];

  preventAlphabet(input: string): boolean {
    const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    let result = true;

    input.split('').map((char: string) => {
      // console.log(`include: ${char}`, numbers.includes(char));
      result = result && numbers.includes(char);
    });
    return result;
  }

  convertPersianToEnglish(strNum: string): string {
    let result: string;
    const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
    const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
    const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    result = strNum.split('').map(c => englishNumbers[persianNumbers.indexOf(c)] || englishNumbers[arabicNumbers.indexOf(c)] || c).join('');
    return result;
  }

  numericalValidationMultiLang(strNum: string): { valid: boolean; value: string; } {
    let result: string;
    let isValid: boolean;

    isValid = this.preventAlphabet(strNum);
    if (isValid) {
      result = this.convertPersianToEnglish(strNum);
      if (strNum !== result) {
        return {
          valid: false,
          value: result
        };
      } else {
        return {
          valid: true,
          value: ''
        };
      }
    } else {
      return {
        valid: false,
        value: strNum.substring(0, strNum.length - 1)
      };
    }
  }

  convertStringToTimeObject(expression: any): { hour: number, minute: number, second: number } {
    expression = isNaN(expression) ? expression : String(expression);
    return {
      hour: parseInt(expression.slice(8, 10), 10),
      minute: parseInt(expression.slice(10, 12), 10),
      second: expression.slice(12, 14) ? parseInt(expression.slice(12, 14), 10) : 0
    };
  }

  convertTimeSpanStringToTime(expression: string): { hour: number, minute: number, second: number } {
    expression = String(expression);
    return {
      hour: parseInt(expression.slice(0, 2), 10),
      minute: parseInt(expression.slice(3, 5), 10),
      second: parseInt(expression.slice(6, 8), 10)
    };
  }

  convertTimeSpanStringToMinutes(expression: string): number {
    expression = String(expression);
    const hour = parseInt(expression.slice(0, 2), 10);
    const minute = parseInt(expression.slice(3, 5), 10);
    return hour * 60 + minute;
  }

  convertStringToDateObject(expression: any): { day: number, month: number, year: number } {
    expression = isNaN(expression) ? expression : String(expression);
    return {
      day: parseInt(expression.slice(6, 8), 10),
      month: parseInt(expression.slice(4, 6), 10),
      year: parseInt(expression.slice(0, 4), 10)
    };
  }

  convertStringToPresentableDate(expression: string): string {
    return `${parseInt(expression.slice(0, 4), 10)}/${parseInt(expression.slice(4, 6), 10)}/${parseInt(expression.slice(6, 8), 10)}`;
  }

  convertTimeObjectToString({hour, minute, second}: any): string {
    hour = hour < 10 ? `0${hour}` : String(hour);
    minute = minute < 10 ? `0${minute}` : String(minute);
    second = second < 10 ? `0${second}` : String(second);
    return `${hour}${minute}${second}`;
  }

  convertDateObjectToString({day, month, year}: any): string {
    day = day < 10 ? `0${day}` : String(day);
    month = month < 10 ? `0${month}` : String(month);
    return `${year}${month}${day}`;
  }

  convertMinuteToTime(expression: string): { hour: number, minute: number } {
    const hours = (parseInt(expression, 10) / 60);
    const exactHours = Math.floor(hours);
    const minutes = (hours - exactHours) * 60;
    const exactMinutes = Math.round(minutes);
    return {hour: exactHours, minute: exactMinutes};
  }

  replaceString(source: string, value: string, index: number): string {
    const result = source.substring(0, index) + value + source.substring(index + 1);
    return result;
  }
  convertRangeTimeInVacationHistory = (time: string) => {
    const hour = parseInt(time.slice(0, 2));
    const min = parseInt(time.slice(3, 5)) ? ':' + parseInt(time.slice(3, 5)) : '';
    return `${hour}${min}`;
  }
}


