import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as moment from 'jalali-moment';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {VacationDurationView} from '../../../main/modules/vacations/models/vacation-duration.view';
import {VacationsService} from '../../../main/modules/vacations/services/vacations.service';
import {MonthRangePickerService} from '../month-range-picker/month-range-picker.service';
import {DateDto} from '../../../main/modules/vacations/models/date.dto';

@UntilDestroy()

@Component({
  selector: 'app-vacation-bar',
  templateUrl: './vacation-bar.component.html',
  styleUrls: ['./vacation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VacationBarComponent implements OnInit {
  vacationData$!: Observable<VacationDurationView>;
  math = Math;
  currentJalaliMonth!: number;
  whichMonth$!: Observable<number>;
  whichDate$!: Observable<DateDto>;
  tempMonth!: number;

  constructor(public vacationService: VacationsService,
              private monthRangePickerService: MonthRangePickerService) {
    this.currentJalaliMonth = Number(moment().locale('fa').format('M'));
  }

  ngOnInit(): void {
    this.whichDate$ = this.monthRangePickerService.whichDate$;
    this.monthRangePickerService.whichDate$
      .pipe(untilDestroyed(this))
      .subscribe(
      res => {
        // console.log(` jajali is: ${this.currentJalaliMonth} &&& currentMonth ${res}`);
        this.tempMonth = res.month;
      }
    );
    this.vacationData$ = this.vacationService.vacationTime$;
  }

}
