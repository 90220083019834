import {ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {HistoryMonthDto} from '../../models/history-month.dto';
import {HistoryBarChartService} from './service/history-bar-chart.service';

@Component({
  selector: 'app-history-bar-chart',
  templateUrl: './history-bar-chart.component.html',
  styleUrls: ['./history-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HistoryBarChartComponent implements OnInit {

  @Input() barChartData!: HistoryMonthDto[];
  @Output() checkMonth = new EventEmitter<number>();
  math = Math;
  selectedId!: number | null;

  constructor(private historyService: HistoryBarChartService) {
  }

  ngOnInit(): void {
  }

  chartEventHandler(monthNumber: number): void {
    if (this.selectedId !== monthNumber) {
      this.checkMonth.emit(monthNumber);
      this.historyService.setMonth(monthNumber);
      this.selectedId = monthNumber;
    }
  }

}
