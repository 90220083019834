<ng-container>
  <app-filter-table (filteredData)="actionFilter($event)"></app-filter-table>
</ng-container>
<ng-container *ngIf="{ deviceMode: resize.deviceMode | async , dataTable: dataTable$ | async} as O">
  <div class="grid-table">

    <!-- if table is empty-->
    <div class="alert-wrap info" *ngIf="O.dataTable.items.length === 0">
      <i class="fas fa-info-circle"></i>
      رکوردی یافت نشد!
    </div>
    <ng-container
      *ngIf="O.dataTable.items.length && (O.deviceMode !== 'md' && O.deviceMode !=='xs' && O.deviceMode !== 'sm')">
      <div class="scrollbar-style">
        <ul class="scrollbar-style-inner">

          <!-- Table header-->
          <li class="header-item">
            <div *ngFor="let headerItem of ObjectKey(tableConfig.headings); let i = index;"
                 [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[i] , maxWidth : tableConfig.cellStyle[i]} : ''"
                 [ngClass]="tableConfig.gridClass? tableConfig!.gridClass[i] : '' "
            >
              {{tableConfig.headings[headerItem]}}
            </div>
            <div class="action-icon" *ngIf="ObjectKey(tableConfig.actions).length"></div>
          </li>

          <!-- Table body-->
          <li class="body-item" *ngFor="let dataRow of O.dataTable.items; ">
            <div *ngFor="let headerItem of ObjectKey(tableConfig.headings); let i = index;"
                 [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[i] , maxWidth : tableConfig.cellStyle[i]} : ''"
                 [ngClass]="(dataRow[headerItem+'CssSelector'] && tableConfig!.gridClass) ? (dataRow[headerItem+'CssSelector'](dataRow[headerItem]) +' '+ tableConfig.gridClass[i]) :
                             dataRow[headerItem+'CssSelector'] ? dataRow[headerItem+'CssSelector'](dataRow[headerItem]) :
                            tableConfig!.gridClass ? tableConfig.gridClass[i] : '' "
            >
              <ng-container *ngIf="headerItem !== 'status';else statusWithIcon " >
                {{dataRow[headerItem]}}
              </ng-container>
              <ng-template #statusWithIcon>
                <ng-container *ngIf="dataRow[headerItem]" [ngSwitch]="dataRow[headerItem]">
                  <app-svg-icon *ngSwitchCase="1" icon="icon-time" style="height: 24px"
                                class="icon-size-24"></app-svg-icon>
                  <app-svg-icon *ngSwitchCase="2" icon="icon-done" style="height: 20px"
                                class="icon-size-20"></app-svg-icon>
                  <app-svg-icon *ngSwitchCase="3" icon="icon-deny" style="height: 20px"
                                class="icon-size-20"></app-svg-icon>
                </ng-container>
              </ng-template>
            </div>
            <ng-container *ngIf="ObjectKey(tableConfig.actions).length">
              <div class="action-icon"
                   [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[tableConfig.cellStyle.length - 1] , maxWidth : tableConfig.cellStyle[tableConfig.cellStyle.length - 1]} : ''"
                   [ngClass]="tableConfig.gridClass? tableConfig.gridClass[tableConfig.gridClass.length - 1] : '' "
              >
              <span *ngFor="let rowAction of ObjectKey(tableConfig.actions)" class="{{rowAction}}">
                <i class="{{tableConfig.actions[rowAction].icon}}" (click)="fireAction(rowAction, dataRow)"></i>
              </span>
              </div>
            </ng-container>

          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container
      *ngIf="O.dataTable.items.length && (O.deviceMode === 'md' || O.deviceMode ==='xs' || O.deviceMode === 'sm')">
      <div *ngFor="let dataRow of O.dataTable.items;" class="table-mobile-mode">

        <div class="row" *ngFor="let headerItem of ObjectKey(tableConfig.headings)">
          <div class="header-item">
            {{tableConfig.headings[headerItem]}}
          </div>
          <div class="body-item"
               [ngClass]="dataRow[headerItem+'CssSelector'] ? dataRow[headerItem+'CssSelector'](dataRow[headerItem])  : '' ">
            <ng-container *ngIf="headerItem !== 'status';else statusWithIcon " >
              {{dataRow[headerItem]}}
            </ng-container>
            <ng-template #statusWithIcon>
              <ng-container *ngIf="dataRow[headerItem]" [ngSwitch]="dataRow[headerItem]">
                <app-svg-icon *ngSwitchCase="1" icon="icon-time" style="height: 24px"
                              class="icon-size-24"></app-svg-icon>
                <app-svg-icon *ngSwitchCase="2" icon="icon-done" style="height: 20px"
                              class="icon-size-20"></app-svg-icon>
                <app-svg-icon *ngSwitchCase="3" icon="icon-deny" style="height: 20px"
                              class="icon-size-20"></app-svg-icon>
              </ng-container>
            </ng-template>
          </div>
        </div>

        <div class="action-icon" *ngFor="let rowAction of ObjectKey(tableConfig.actions)">
          <i class="{{tableConfig.actions[rowAction].icon}}" (click)="fireAction(rowAction, dataRow)"></i>
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="O.dataTable.totalPagesCount > 1">
      <div class="pagination" *ngIf="O.dataTable!.totalItemsCount > 0 ">
        <div class="pagination_navigation">
          <ngb-pagination *ngIf="tableConfig.needPagination && O.dataTable.items.length > 0"
                          (pageChange)="pageChanged($event)"
                          [pageSize]="O.dataTable.pageSize"
                          [page]="O.dataTable.pageNumber"
                          [collectionSize]="O.dataTable.totalItemsCount">
            <ng-template ngbPaginationPrevious>
              <app-svg-icon class="pagination-custom-icon" icon="icon-arrowRight"></app-svg-icon>
            </ng-template>
            <ng-template ngbPaginationNext>
              <app-svg-icon class="pagination-custom-icon" icon="icon-arrowLeft"></app-svg-icon>
            </ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </ng-container>

  </div>
</ng-container>
