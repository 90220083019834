<div class="custom-modal">
  <div class="modal-body" *ngIf="vacationItem">
    <ng-container *ngIf="vacationItem.alternativeFullName && userRole.role === userRoleEnum.Hr">
      <div class="alert alert-primary">
        <app-svg-icon icon="icon-info" class="icon"></app-svg-icon>
        <p>
          فرد جایگزین {{vacationItem.alternativeFullName}} است.
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="vacationItem.emergencyReason">
      <div class="alert alert-warning">
        <app-svg-icon icon="icon-info" class="icon"></app-svg-icon>
        <p>
          {{ vacationItem.emergencyReason | emergencyReason}}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="vacationItem.description && userRole.role === userRoleEnum.Hr">
      <div class="vacation-reason">
        {{ vacationItem.description}}
      </div>
    </ng-container>

    <ng-container *ngIf="vacationItem.supervisorDescription && userRole.role === userRoleEnum.User">
      <div class="vacation-reason">
        دلیل رد مدیر: {{ vacationItem.supervisorDescription}}
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="d-flex align-items-center justify-content-center mt-4">
      <button class="btn btn-dark-gray" (click)="activeModal.dismiss('Cross Click')">بازگشت</button>
    </div>
  </div>
</div>
