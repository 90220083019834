import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from './config.service';
import {OneInputModalComponent} from '../components/one-input-modal/one-input-modal.component';
import {DeleteModalComponent} from '../components/delete-modal/delete-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModal: NgbModal,
              private configService: ConfigService) {
  }

  async oneInput(initialValue: any, text: any, header: any, modalConfirmButton?: any, modalCancelButton?: any, svg?: any,
                 inputConf?: { placeholder?: string, maxLength?: number, minLength?: null, type?: string }): Promise<string> {
    const oneInputModal = this.ngbModal.open(OneInputModalComponent, this.configService.modalOptions());
    oneInputModal.componentInstance.initialValue = initialValue;
    oneInputModal.componentInstance.modalText = text;
    oneInputModal.componentInstance.modalHeader = header;
    oneInputModal.componentInstance.modalConfirmButton = modalConfirmButton;
    oneInputModal.componentInstance.modalCancelButton = modalCancelButton;
    oneInputModal.componentInstance.inputConf = inputConf;
    oneInputModal.componentInstance.svg = svg;
    return oneInputModal.result;
  }

  async delete(text: any, modalConfirmButton?: any, modalCancelButton?: any, svg?: any): Promise<any> {
    const deleteModal = this.ngbModal.open(DeleteModalComponent, this.configService.modalOptions());
    deleteModal.componentInstance.modalText = text;
    deleteModal.componentInstance.modalConfirmButton = modalConfirmButton;
    deleteModal.componentInstance.modalCancelButton = modalCancelButton;
    deleteModal.componentInstance.svg = svg;
    return deleteModal.result;
  }

  // async confirm(text, header, modalConfirmButton?, modalCancelButton?, svg?): Promise<any> {
  //   const confirmModal = this.ngbModal.open(ConfirmModalComponent, this.configService.modalOptions());
  //   confirmModal.componentInstance.modalText = text;
  //   confirmModal.componentInstance.modalHeader = header;
  //   confirmModal.componentInstance.modalConfirmButton = modalConfirmButton;
  //   confirmModal.componentInstance.modalCancelButton = modalCancelButton;
  //   confirmModal.componentInstance.svg = svg;
  //   return confirmModal.result;
  // }
  //
  // async showData(text, header, data: ShowDataModalInterface, svg?) {
  //   const showDataModal = this.ngbModal.open(ShowDataModalComponent, this.configService.modalOptions());
  //   showDataModal.componentInstance.modalText = text;
  //   showDataModal.componentInstance.modalHeader = header;
  //   showDataModal.componentInstance.data = data;
  //   showDataModal.componentInstance.svg = svg;
  // }

}
