import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ResizeService} from '../../services/resize.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActionDispatchModel} from '../../models/action-dispatch.model';
import {TableConfigModel} from '../../models/table-config';
import {DeviceType} from '../../models/device-type';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  NgbPagination,
  NgbPaginationNext, NgbPaginationNumber,
  NgbPaginationPrevious
} from '@ng-bootstrap/ng-bootstrap';
import {TableServiceService} from './service/table-service.service';



@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbPagination, NgbPaginationNext, NgbPaginationPrevious, NgbPaginationNumber]

})
export class TableComponent implements OnInit, OnChanges {
  @Output() actionDispatched: EventEmitter<ActionDispatchModel> = new EventEmitter<ActionDispatchModel>();
  @Output() pageChangedDispatched: EventEmitter<number> = new EventEmitter<number>();
  @Output() filtersDispatch = new EventEmitter();
  @Input() tableConfig!: TableConfigModel;
  @Input() dataTable!: any;
  @Input() currentMonth!: number;
  dataTableSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataTable$: Observable<any> = this.dataTableSubject$.asObservable();

  ObjectKey = Object.keys;
  deviceMode!: DeviceType;
  filterPara: any = {};
  filterList: any = {};

  constructor(public resize: ResizeService,
              private tableService: TableServiceService
  ) {
    this.resize.deviceMode
      .pipe(untilDestroyed(this))
      .subscribe(d => this.deviceMode = d);

    this.dataTableSubject$.next(this.dataTable);
  }

  actionFilter(data: any): void {
    this.filterPara = data;
  }

  ngOnInit(): void {
    this.dataTableSubject$.next(this.dataTable);
    // this.tableService.filterInfo.next(this.filterItem.emit(this.filterPara));
    this.tableService.filterInfo$.pipe(untilDestroyed(this)).subscribe(res => {
      this.filterList = {
        ...this.filterList,
        ...res
      };
      if (this.filterList.hasOwnProperty('pageNumber')) {
        this.filterList.pageNumber = 1;
      }
      this.filtersDispatch.emit(this.filterList);
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable.currentValue) {
      this.dataTableSubject$.next(changes.dataTable.currentValue);
      // if (changes.dataTable.firstChange) {
      //   this.firstLoad = true;
      // }
    }
  }

  /*  identify(index: number, item: any): number {
      return item.id;
    }*/

  fireAction(actionTitle: string, data: any): void {
    // console.log(`fireAction-> ${actionTitle} : `, data);
    this.actionDispatched.emit({actionTitle, data});
  }

  pageChanged(pageNumber: number): void {
    this.filterList = {
      ...this.filterList,
      pageNumber
    };
    this.filtersDispatch.emit(this.filterList);
  }

}
