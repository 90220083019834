import {Injectable} from '@angular/core';
import {RefactorCalendarListDto} from '../../../main/modules/vacations/models/refactor-calendar-list.dto';
import {BehaviorSubject, Observable} from 'rxjs';
import {DaysOfMonthView} from '../../../main/modules/vacations/models/days-of-month.view';
import {CalendarDataView} from '../../../main/modules/vacations/models/calendar-data.view';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  // @ts-ignore
  calendarListSubject$: BehaviorSubject<RefactorCalendarListDto[]> = new BehaviorSubject<RefactorCalendarListDto[]>(undefined);
  calendarList$: Observable<RefactorCalendarListDto[]> = this.calendarListSubject$.asObservable();

  constructor() {
  }

  setCalendarList(obj: RefactorCalendarListDto[]): void {
    return this.calendarListSubject$.next(obj);
  }

  refactorCalendarEmployeeData(calendarData: CalendarDataView): void {
    let manipulatedDays: RefactorCalendarListDto[] = [];
    calendarData.daysOfMonth.map((item: DaysOfMonthView) => {
      let vacationStatus;
      calendarData.vacationDays.find(i => {
        if (i.day === item.day) {
          vacationStatus = i.status;
        }
      });

      const tempDay = new RefactorCalendarListDto(item.day, item.dayOfWeek, item.holidayDescription, (vacationStatus ? vacationStatus : null));
      manipulatedDays = manipulatedDays.concat(tempDay);
    });

    // console.log('manipulatedDays-------------------', manipulatedDays)
    this.setCalendarList(manipulatedDays);
  }

  refactorCalendarHrData(calendarData: any): void {
    let manipulatedDays: RefactorCalendarListDto[] = [];

    calendarData.daysOfMonth.map((item: DaysOfMonthView) => {
      let hourlyCount;
      let dailyCount;
      let tempDay;
      calendarData.groupedVacationDays.find((i: { day: number; hourlyVacationCount: any; dailyVacationCount: any; }) => {

        if (i.day === item.day) {
          hourlyCount = i.hourlyVacationCount;
          dailyCount = i.dailyVacationCount;
        }
      });

      tempDay = new RefactorCalendarListDto(item.day, item.dayOfWeek, item.holidayDescription, null, hourlyCount, dailyCount);
      manipulatedDays = manipulatedDays.concat(tempDay);
    });
    this.setCalendarList(manipulatedDays);
  }
}
