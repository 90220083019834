import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-time-count',
  templateUrl: './time-count.component.html',
  styleUrls: ['./time-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCountComponent implements OnInit {
  countDown!: Subscription;
  counter!: number;
  tick = 1000;
  @Output() resendCode: EventEmitter<number> = new EventEmitter<number>();
  @Input() counterPeriod!: number ;

  constructor( private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): any {
    this.counter = this.counterPeriod;
    this.countDown = timer(0, this.tick)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.counter > 0) {
          this.changeDetectorRef.markForCheck();
          return --this.counter;
        }
        return true;
      });
  }

  stopTimer(): void{
    this.countDown.unsubscribe();
  }

  resend(): void {
    this.stopTimer();
    this.startTimer();
    this.resendCode.emit();
  }

}
