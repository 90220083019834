import {ChangeDetectionStrategy, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'app-time-counter',
  templateUrl: './time-counter.component.html',
  styleUrls: ['./time-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimeCounterComponent implements OnInit, OnChanges {
  @Input() step = '15';
  @Input() defaultTime: string | null = '00:00:00';
  @Input() min = 0;
  @Input() max = 1425;

  @Output() getTime: EventEmitter<string> = new EventEmitter<string>();


  time = {
    hour: 0,
    minute: 0,
    second: 0,
  };
  isIncrease = true;
  isDecrease = true;

  constructor(private utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init(): void {
    this.defaultTime = this.defaultTime ? this.defaultTime : '00:00:00';
    this.time = this.utilityService.convertTimeSpanStringToTime(this.defaultTime);
    this.max = this.max > 1425 ? 1425 : this.max;
    this.min = this.min < 0 ? 0 : this.min;
    this.step = parseInt(this.step, 10) >= 60 ? '60' : this.step;
    this.checkDefault();
    this.getTime.emit(this.returnTime());

  }

  checkDefault(): void {
    const maxTime = this.utilityService.convertMinuteToTime(this.max.toString());
    const minTime = this.utilityService.convertMinuteToTime(this.min.toString());
    if (this.time.hour < minTime.hour && this.time.minute <= minTime.minute) {
      this.time.hour = minTime.hour;
      this.time.minute = minTime.minute;
      this.isDecrease = false;
    }
    if (this.time.hour >= maxTime.hour && this.time.minute >= maxTime.minute) {
      this.time.hour = maxTime.hour;
      this.time.minute = maxTime.minute;
      this.isIncrease = false;
    }

  }

  increaseHandler(): void {
    this.isDecrease = true;
    const stepTime = this.utilityService.convertMinuteToTime(this.step);
    if (stepTime.hour === 1 && stepTime.minute === 0) {
      stepTime.hour = 0;
      stepTime.minute = 60;
    }

    const maxTime = this.utilityService.convertMinuteToTime(this.max.toString());
    if (this.time.minute + stepTime.minute >= 60) {
      const extraTime = this.utilityService.convertMinuteToTime((this.time.minute + stepTime.minute).toString());
      if (this.time.hour + extraTime.hour > maxTime.hour) {
        this.time.hour = maxTime.hour;
        this.time.minute = maxTime.minute;
        this.isIncrease = false;
      } else {
        this.time.hour += (extraTime.hour + stepTime.hour);
        this.time.minute = extraTime.minute;
        if (this.time.hour === maxTime.hour && this.time.minute > maxTime.minute) {
          this.time.minute = maxTime.minute;
        }
      }

    } else {
      this.time.hour += stepTime.hour;
      this.time.minute += stepTime.minute;
      if (this.time.hour >= maxTime.hour && (this.time.minute >= maxTime.minute || stepTime.hour > 0)) {
        this.time.hour = maxTime.hour;
        this.time.minute = maxTime.minute;
        this.isIncrease = false;
      }
    }
    this.getTime.emit(this.returnTime());
  }

  decreaseHandler(): void {
    this.isIncrease = true;
    const stepTime = this.utilityService.convertMinuteToTime(this.step);
    const minTime = this.utilityService.convertMinuteToTime(this.min.toString());
    if (this.time.minute - stepTime.minute < 0) {
      const extraTime = this.utilityService.convertMinuteToTime((Math.abs(this.time.minute - stepTime.minute) + 60).toString());
      if (this.time.hour - extraTime.hour < minTime.hour && (this.time.minute - stepTime.minute <= minTime.minute || stepTime.hour > 0)) {
        this.time.hour = minTime.hour;
        this.time.minute = minTime.minute;
        this.isDecrease = false;
      } else {
        this.time.hour -= (extraTime.hour + stepTime.hour);
        this.time.minute = 60 - extraTime.minute;
        if (this.time.hour <= minTime.hour && this.time.minute < minTime.minute) {
          this.time.hour = minTime.hour;
          this.time.minute = minTime.minute;
        }
      }
    } else {
      this.time.hour -= stepTime.hour;
      this.time.minute -= stepTime.minute;
      if (this.time.hour <= minTime.hour && (this.time.minute <= minTime.minute || stepTime.hour > 0)) {
        this.time.hour = minTime.hour;
        this.time.minute = minTime.minute;
        this.isDecrease = false;
      }
    }
    this.getTime.emit(this.returnTime());
  }

  returnTime(): string {
    return `${this.time.hour > 9 ? this.time.hour : '0' + this.time.hour.toString()}:${this.time.minute > 9 ? this.time.minute : '0' + this.time.minute.toString()}:${this.time.second > 9 ? this.time.second : '0' + this.time.second.toString()}`;
  }
}
