import {Component, OnInit} from '@angular/core';
import {MainService} from '../../main.service';
import {ResizeService} from '../../../shared/services/resize.service';
import {TokenService} from '../../../shared/services/token.service';
import {Router} from '@angular/router';
import {Sidebar} from './models/sidebar';
import {AccessService} from '../../../shared/services/access.service';
import {RoleType} from '../../../shared/enums/role-type.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuList: Sidebar[] = [
    // {
    //   icon: 'icon-dashboard',
    //   title: 'دشبورد',
    //   href: '/test',
    //   showToEmployee: true
    // },
    {
      icon: 'icon-user',
      title: 'پروفایل',
      href: '/profile',
      showToEmployee: true
    },
    // {
    //   icon: 'icon-notification',
    //   title: 'اعلانات',
    //   href: '/test',
    //   showToEmployee: true
    // },
    {
      icon: 'icon-request',
      title: 'ثبت مرخصی',
      href: '/vacations',
      showToEmployee: true
    },
    {
      icon: 'icon-building',
      title: 'مدیریت دپارتمان ها',
      href: '/departments',
      showToEmployee: false
    },
    {
      icon: 'icon-group',
      title: 'اعضا',
      href: '/staff',
      showToEmployee: false
    },
    {
      icon: 'icon-notification',
      title: 'اعلان ها',
      href: '/notifications',
      showToEmployee: true
    }
  ];
  customMenu: Sidebar[] = [];
  roleType = RoleType;

  constructor(public mainService: MainService,
              public resizeService: ResizeService,
              private tokenService: TokenService,
              private accessService: AccessService) {
  }

  ngOnInit(): void {
    this.getSidebarMenu();
  }

  signOut(): void {
    this.mainService.menuHandler(false);
    this.tokenService.removeAllToken();
    document.location.href = '/';
  }

  getSidebarMenu(): void {
    if (this.accessService.roleType !== this.roleType.Employee) {
      this.customMenu = this.menuList;
    } else {
      this.menuList.forEach(f => {
        if (f.showToEmployee) {
          this.customMenu.push(f);
        }
      });
    }
    if (this.accessService.hasClaim(['ModifyOrConfirmVacations'])) {
      this.customMenu.push({
        icon: 'icon-team-management',
        title: 'مدیریت تیم',
        href: '/team-management',
        showToEmployee: false
      });
    }
  }

  /*  getSidebarMenu(): Sidebar[] {
      if (this.accessService.roleType !== this.roleType.Employee) {
        return this.menuList;
      }
      const customMenu: Sidebar[] = [];
      this.menuList.forEach(f => {
        if (f.showToEmployee) {
          customMenu.push(f);
        }
      });
      return customMenu;
    }*/
}
