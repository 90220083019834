import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {VacationTypeEnum} from '../../../../../shared/enums/vacation-type.enum';
import {TakeDailyVacationAsManagerModalComponent} from '../../../team-management/components/take-daily-vacation-as-manager-modal/take-daily-vacation-as-manager-modal.component';
import {TakeHourlyVacationAsManagerModalComponent} from '../../../team-management/components/take-hourly-vacation-as-manager-modal/take-hourly-vacation-as-manager-modal.component';
import {MonthRangePickerService} from '../../../../../shared/components/month-range-picker/month-range-picker.service';

@Component({
  selector: 'app-take-leave-modal',
  templateUrl: './take-leave-modal.component.html',
  styleUrls: ['./take-leave-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeLeaveModalComponent implements OnInit {
  @Input() userId!: string;
  isManager!: boolean;
  vacationType = VacationTypeEnum;

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private monthRangePickerService: MonthRangePickerService) {
  }

  ngOnInit(): void {
    this.isManager = !!this.userId;
    // console.log(this.isManager);
    if (!this.isManager) {
      this.monthRangePickerService.whichDate$.subscribe(
        res => {
          sessionStorage.setItem('selectedMonth', String(res.month));
          sessionStorage.setItem('selectedYear', String(res.year));
        }
      );
    }
  }

  takeLeaveAsManager(type: VacationTypeEnum): void {
    this.activeModal.dismiss('Cross Click');
    let modal: NgbModalRef;

    if (type === VacationTypeEnum.Daily) {
      modal = this.modalService.open(TakeDailyVacationAsManagerModalComponent, {centered: true, windowClass: 'take-leave-asManager'});
    } else {
      modal = this.modalService.open(TakeHourlyVacationAsManagerModalComponent, {centered: true, windowClass: 'take-leave-asManager'});
    }

    modal.componentInstance.userId = this.userId;
  }

}
