import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryBarChartService {
  private selectedMonth: ReplaySubject<number> = new ReplaySubject<number>(1);
  selectedMonth$: Observable<number> = this.selectedMonth.asObservable();
  constructor() { }
  setMonth(month: number): any {
    this.selectedMonth.next(month);
  }
}
