<div class="details-wrapper">
  <div class="details-wrapper-right">
    <div class="details-wrapper-image">
      <span *ngIf="!imageSrc">
      <app-svg-icon *ngIf="personalProfile" icon="icon-camera" class="icon"></app-svg-icon>
      <app-svg-icon *ngIf="!personalProfile" icon="icon-ghost" class="icon"></app-svg-icon>
    </span>
      <div class="details-wrapper-show" *ngIf="imageSrc">
        <img [src]="imageSrc" alt="">

        <div class="details-wrapper-overlay" *ngIf="personalProfile">
          <span class="trash" (click)="removeDepartmentImage()">
            <app-svg-icon icon="icon-trash" class="icon"></app-svg-icon>
          </span>
          <label class="edit" for="modify-department-image">
            <app-svg-icon icon="icon-camera" class="icon"></app-svg-icon>
          </label>
        </div>
      </div>
      <input type="file"
             id="modify-department-image"
             name="filename"
             accept="image/gif, image/jpeg, image/png"
             #imageInput
             *ngIf="personalProfile"
             (change)="selectImage($event)">
    </div>
  </div>
  <div class="details-wrapper-left">
    <div class="details-wrapper-specifications">
      <ul class="personal-information">
        <li>
          <app-svg-icon icon="icon-id" class="icon"></app-svg-icon>
          <span>{{profileData.firstName}} {{profileData.lastName}}</span>
        </li>
        <li>
          <app-svg-icon icon="icon-user" class="icon"></app-svg-icon>
          <span>{{profileData.userName}}</span>
        </li>
        <li>
          <app-svg-icon icon="icon-mobile" class="icon"></app-svg-icon>
          <span>{{profileData.mobile}}</span>
        </li>
        <li *ngIf="profileData.personalCode">
          <app-svg-icon icon="icon-user" class="icon"></app-svg-icon>
          <span>{{profileData.personalCode}}</span>
        </li>
      </ul>
      <div class="personal-details">
        <div class="personal-details-department">
          <span>{{profileData.departmentTitle}}</span>
          <div class="personal-details-department-img" *ngIf="profileData.departmentImageUrl">
            <img src="{{profileData.departmentImageUrl}}" alt="{{profileData.departmentTitle}}">
          </div>
        </div>
        <div class="personal-details-shift personal-details-scroll">
          <h6>{{profileData.departmentShift.title}}</h6>
          <ul>
            <li *ngFor="let daily of departmentService.groupByDaily(profileData.departmentShift.dailyWorkingHours)">
              <p>
              <span *ngFor="let day of daily;let last = last">
                {{day.dayOfWeek | weekday}}
                <ng-container *ngIf="!last">،</ng-container>
              </span>
              </p>
              <span>{{daily[0].startTime.slice(0, 5)}} تا {{daily[0].endTime.slice(0, 5)}}</span>
            </li>
          </ul>
        </div>
        <div class="personal-details-other personal-details-scroll">
          <h6>مشخصات</h6>
          <ul>
            <li>
              <span>
                {{profileData.jobTitle}}
              </span>
            </li>
            <li>
              <span>
                {{profileData.email}}
              </span>
            </li>
            <li *ngIf="profileData.nationalId">
              <span>
                کد ملی: {{profileData.nationalId}}
              </span>
            </li>
            <li *ngIf="profileData.emergencyPhoneNumber">
               <span>
                شماره اضطراری: {{profileData.emergencyPhoneNumber}}
              </span>
            </li>
            <li *ngIf="profileData.phone">
               <span>
                شماره تماس: {{profileData.phone}}
              </span>
            </li>
            <li *ngIf="profileData.birthDay">
              <span>
                تاریخ تولد {{profileData.birthDay | convertDateToString}}
              </span>
            </li>
            <li>
              <span>
                تاریخ عضویت {{profileData.startedDate | convertDateToString}}
              </span>
            </li>
            <li *ngIf="profileData.insuranceStartAt">
               <span>
                تاریخ شروع بیمه {{profileData.insuranceStartAt | convertDateToString}}
              </span>
            </li>
            <li *ngIf="profileData.contractExpireDate">
               <span>
                تاریخ پایان قرارداد {{profileData.contractExpireDate | convertDateToString}}
              </span>
            </li>
            <li *ngIf="profileData.isMale">
               <span>
                جنسیت:  {{profileData.isMale ? 'مرد' : 'زن'}}
              </span>
            </li>
            <li *ngIf="profileData.fatherName">
               <span>
                نام پدر:  {{profileData.fatherName}}
              </span>
            </li>
            <li *ngIf="profileData.militaryStatus">
               <span>
                نظام وظیفه:  {{profileData.militaryStatus | military}}
              </span>
            </li>
            <li *ngIf="profileData.isMarried">
               <span>
                وضعیت تاهل:  {{profileData.isMarried ? 'متاهل' : 'مجرد'}}
              </span>
            </li>
            <li *ngIf="profileData.kidsCount">
               <span>
                تعداد فرزند:  {{profileData.kidsCount}}
              </span>
            </li>
            <li *ngIf="profileData.educationDegree">
               <span>
                آخرین مدرک تحصیلی:  {{profileData.educationDegree | educationDegree}}
              </span>
            </li>
            <li *ngIf="profileData.bloodType">
               <span>
                گروه خونی:  {{profileData.bloodType | bloodTypePipe}}
              </span>
            </li>
            <li *ngIf="profileData.mbti">
               <span>
                تیپ شخصیتی:  {{profileData.mbti | mBTI}}
              </span>
            </li>
            <li *ngIf="profileData.postalCode">
               <span>
                کد پستی:  {{profileData.postalCode}}
              </span>
            </li>
            <li *ngIf="profileData.province">
               <span>
                 استان {{profileData.province | province}}، {{profileData.city}}، {{profileData.addressDescription}}
              </span>
            </li>
            <li *ngIf="profileData.rareDisease">
               <span>
                بیماری خاص:  {{profileData.rareDisease}}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="specifications-edit">
        <a class="btn btn-yellow w-100"
           [routerLink]="['/departments',profileData.departmentId,'staff', personalProfile ? accessService.userId : routeParams]"
           *ngIf="!personalProfile || (personalProfile && accessService.roleType !== roleType.Employee)">ویرایش</a>
        <button [disabled]="true" class="btn btn-green w-100"
                *ngIf="(personalProfile && accessService.roleType === roleType.Employee)">درخواست ویرایش
        </button>
      </div>
    </div>
  </div>
</div>
<ul class="user-access-row row-custom">
  <!--  !personalProfile || (personalProfile && accessService.roleType !== roleType.Employee)-->
  <li class="col-custom" *ngIf="!personalProfile || (!personalProfile && accessService.roleType !== roleType.Employee)">
    <div class="green-gradiant-btn gradiant-btn" (click)="takeLeave()">
      <app-svg-icon [icon]="'icon-add'" class=" icon"></app-svg-icon>
      <span>افزودن مرخصی</span>
    </div>
  </li>

  <li class="col-custom">
    <div class="cyan-gradiant-btn gradiant-btn" (click)="navigateToHistory()">
      <app-svg-icon [icon]="'icon-chart-infographic'" class=" icon"></app-svg-icon>
      <span>آمار مرخصی ها</span>
    </div>
  </li>
</ul>
