import {Pipe, PipeTransform} from '@angular/core';

import {VacationStatusEnum} from '../enums/vacation-status.enum';

@Pipe({
  name: 'vacationStatus'
})

export class VacationStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case VacationStatusEnum.Confirmed:
        return 'تایید شده';
      case VacationStatusEnum.Rejected:
        return 'عدم تایید';
      case VacationStatusEnum.Pending:
        return 'در انتظار تایید';
      default:
        return 'نامشخص';
    }
  }
}
