import {Pipe, PipeTransform} from '@angular/core';
import {UtilityService} from '../services/utility.service';

@Pipe({
  name: 'convertDateToString'
})
export class ConvertDateToStringPipe implements PipeTransform {
  constructor(private utilityService: UtilityService) {
  }

  transform(value: number): string {
    const date = value.toString();
    const monthId = parseInt(date.slice(4, 6), 10);
    const monthName = this.utilityService.monthsOfYear.find(f => f.value === monthId);

    return `${date.slice(6, 8)} ${monthName?.name} ${date.slice(0, 4)}`;
  }

}
