import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map} from 'rxjs/operators';

import {ApiService} from '../../../../shared/services/api.service';
import {UtilityService} from '../../../../shared/services/utility.service';
import {CalendarService} from '../../../../shared/components/calendar/calendar.service';
import {ToggleSwitchService} from '../../../../shared/components/toggle-switch/toggle-switch.service';
import {MonthRangePickerService} from '../../../../shared/components/month-range-picker/month-range-picker.service';
import {ModalService} from '../../../../shared/services/modal.service';
import {ToastifyService} from '../../../../shared/services/toastify.service';
import {ResponseModel} from '../../../../shared/models/response-model';
import {VacationsDataSharedView} from '../models/vacations-data-shared.view';
import {CalendarDataView} from '../models/calendar-data.view';
import {VacationDurationView} from '../models/vacation-duration.view';
import {SingleCalendarDataView} from '../models/single-calendar-data.view';
import {AlternativesView} from '../models/alternatives.view';
import {VacationsHistoryView} from '../../../../shared/models/vacations-history.view';
import {PagedDataView} from '../../../../shared/models/paged-data.view';
import {VacationHistoryListItemTranslateDto} from '../../../../shared/models/vacation-history-list-item-translate.dto';
import {VacationHistoryParamsView} from '../../../../shared/models/vacation-history-params.view';

@UntilDestroy()

@Injectable({
  providedIn: 'root'
})
export class VacationsService {

  // @ts-ignore
  vacationTimeSubject$: BehaviorSubject<VacationDurationView> = new BehaviorSubject<VacationDurationView>(undefined);
  vacationTime$: Observable<VacationDurationView> = this.vacationTimeSubject$.asObservable();

  // @ts-ignore
  vacationsListSubject$: BehaviorSubject<VacationsDataSharedView[]> = new BehaviorSubject<VacationsDataSharedView[]>(undefined);
  vacationItems$: Observable<VacationsDataSharedView[]> = this.vacationsListSubject$.asObservable();

  // @ts-ignore
  alternativesListSubject$: BehaviorSubject<AlternativesView[]> = new BehaviorSubject<AlternativesView[]>(undefined);
  alternativeMembers$: Observable<AlternativesView[]> = this.alternativesListSubject$.asObservable();

  constructor(private apiService: ApiService,
              private calendarService: CalendarService,
              private toggleSwitchService: ToggleSwitchService,
              private monthRangePickerService: MonthRangePickerService,
              private modalService: ModalService,
              private toastifyService: ToastifyService,
              private utilityService: UtilityService) {
  }

  getCalendarData(month: string, year: string): Observable<ResponseModel<CalendarDataView>> {
    const url = `Vacation/myvacations?year=${year}&month=${month}`;
    return this.apiService.get(url, true);
  }

  getVacationDuration(month: string, year: string): Observable<ResponseModel<VacationDurationView>> {
    const url = `Vacation?year=${year}&month=${month}`;
    return this.apiService.get(url, true);
  }

  deleteVacation(vacationId: number, isManager: boolean): Observable<ResponseModel<boolean>> {
    const url = `Vacation/${vacationId}?isManager=${isManager}`;
    return this.apiService.delete(url, true);
  }

  getSingleCalendarData(month: string, year: string): Observable<ResponseModel<SingleCalendarDataView>> {
    const url = `Vacation/days/status?year=${year}&month=${month}`;
    return this.apiService.get(url, true);
  }

  getAlternatives(departmentId: number, type: number, dates?: string, from?: string | null, to?: string | null): Observable<ResponseModel<AlternativesView[]>> {
    let queryString = `Type=${type}&`;
    queryString = dates ? queryString.concat(`Dates=${dates}`) : queryString;
    queryString = from ? queryString.concat(`&From=${from}&`) : queryString;
    queryString = to ? queryString.concat(`&To=${to}`) : queryString;

    const url = `Vacation/alternatives?DepartmentId=${departmentId}&${queryString}`;
    return this.apiService.get(url, true);
  }

  setVacation(payload: object): Observable<ResponseModel<boolean>> {
    const url = `Vacation`;
    return this.apiService.post(url, payload, true);
  }

  checkEmergencyVacation(payload: object): Observable<ResponseModel<null | number>> {
    const url = 'Vacation/checkEmergency';
    return this.apiService.post(url, payload, true);
  }

  getVacationsHistory(userId: string, year: string): Observable<ResponseModel<VacationsHistoryView>> {
    const url = `Staff/${userId}/vacations/history?year=${year}`;
    return this.apiService.get(url, true);
  }

  getVacationListPerMonth(params: VacationHistoryParamsView): Observable<ResponseModel<PagedDataView<any>>> {
    const url = `vacation/list`;
    return this.apiService.get(url, true, params);
  }

  vacationListHandler(params: VacationHistoryParamsView): Observable<ResponseModel<PagedDataView<VacationHistoryListItemTranslateDto[]>>> {
    return this.getVacationListPerMonth(params)
      .pipe(untilDestroyed(this),
        map(res => {
          res.result.items.map((item: any) => {
            item.date = this.utilityService.convertStringToPresentableDate(String(item.date));
            item.type = item.type === 1 ? 'ساعتی' : 'روزانه';
            item.emergencyReason = item.emergencyReason ? 'اضطراری' : 'عادی';
            item.from = item.from ? `${this.utilityService.convertRangeTimeInVacationHistory(item.from)} - ${this.utilityService.convertRangeTimeInVacationHistory(item.to)}` : '-';
          });
          return res;
        })
      );

  }

  setVacationTime(obj: VacationDurationView): void {
    return this.vacationTimeSubject$.next(obj);
  }

  setVacationsList(obj: VacationsDataSharedView[]): void {
    return this.vacationsListSubject$.next(obj);
  }

  setAlternativesList(obj: AlternativesView[]): void {
    return this.alternativesListSubject$.next(obj);
  }

  gotoMonth(month: number, year: number): void {
    this.monthRangePickerService.setMonthAndYear(month, year);
    forkJoin([
      this.getCalendarData(String(month), String(year)),
      this.getVacationDuration(String(month), String(year))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(result => {
          this.toggleSwitchService.setToggleSwitchStatus(false);
          this.calendarService.refactorCalendarEmployeeData(result[0].result);
          this.setVacationTime(result[1].result);
          this.setVacationsList(result[0].result.vacations);
        }
      );
  }

  deletedVacationItem(vacationId: number): void {
    const isManager = false;

    this.modalService.delete('آیا موافق حذف این مرخصی هستید؟')
      // this.modalService.delete('آیا موافق حذف این مرخصی هستید؟', null, 'انصراف')
      .then(res => {
        const temporaryVacationList = this.vacationsListSubject$.getValue();

        this.deleteVacation(vacationId, isManager)
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            if (response.success) {
              temporaryVacationList.forEach((value, index) => {
                if (value.id === vacationId) {
                  temporaryVacationList.splice(index, 1);
                  this.setVacationsList(temporaryVacationList);
                }
              });
              this.toastifyService.success('مرخصی با موفقیت حذف شد.');
            }
          });
      })
      .catch(reason => console.log(reason));
  }

}


