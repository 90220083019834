<div class="sidebar-wrapper"
     *ngIf="{deviceMode: resizeService.deviceMode |async, hasNotification: mainService.hasNotification$|async} as O"
     [ngClass]="{'isOpen':(mainService.menuToggle$ | async)}">
  <div class="sidebar-top">
    <div class="sidebar-logo">
      <a href="/">
        <img src="../../../../assets/images/Logo.svg" alt="logo">
      </a>
    </div>
    <div class="sidebar-menu">
      <ul class="sidebar-list">
        <!--<li class="sidebar-list-item" *ngFor="let item of getSidebarMenu()">-->
        <li class="sidebar-list-item" *ngFor="let item of customMenu">
          <a [routerLink]="[item.href]"
             routerLinkActive="active"
             (click)="mainService.menuHandler(false)">
            <span class="notification" *ngIf="item.href ==='/notifications' && O.hasNotification"></span>
            <app-svg-icon [icon]="item.icon" class="sidebar-list-item-icon icon icon-size-40"></app-svg-icon>
            <div class="sidebar-list-item-text">
              <span>{{item.title}}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar-fixed">
    <ul class="sidebar-list">
      <!--<li class="sidebar-list-item">
        <a [routerLink]="['/test']"
           (click)="mainService.menuHandler(false)"
           routerLinkActive="active">
          <app-svg-icon icon="icon-help" class="sidebar-list-item-icon icon icon-size-40"></app-svg-icon>
          <div class="sidebar-list-item-text">
            <span>پرسش و پاسخ</span>
          </div>
        </a>
      </li>-->
      <li class="sidebar-list-item">
        <button type="button" (click)="signOut()">
          <app-svg-icon icon="icon-logOut" class="sidebar-list-item-icon icon icon-size-40"></app-svg-icon>
          <div class="sidebar-list-item-text">
            <span>خروج</span>
          </div>
        </button>
      </li>
    </ul>
  </div>
</div>
<ng-container *ngIf="{deviceMode: resizeService.deviceMode | async} as O">
  <span class="menu-overlay"
        *ngIf="O.deviceMode == 'sm' || O.deviceMode == 'xs'"
        [ngClass]="{'isOpen':(mainService.menuToggle$ | async)}"
        (click)="mainService.menuHandler(false)"></span>
</ng-container>
