import {Component, Input, OnInit} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent extends Toast {
  constructor( private router: Router,
               protected  toastrService: ToastrService,
               public  toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  navigateToNotification(): void {
    this.router.navigate(['notifications']).then();
  }
}
