import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateDto} from '../../../main/modules/vacations/models/date.dto';
import * as moment from 'jalali-moment';

@Injectable({
  providedIn: 'root'
})
export class MonthRangePickerService {

  private whichDateSubject$: BehaviorSubject<DateDto>;
  whichDate$: Observable<DateDto>;

  constructor() {
    const currentMonthJalali = Number(moment().locale('fa').format('M'));
    const currentYearJalali = Number(moment().locale('fa').format('YYYY'));
    this.whichDateSubject$ = new BehaviorSubject<DateDto>({month: currentMonthJalali, year: currentYearJalali});
    this.whichDate$ = this.whichDateSubject$.asObservable();
  }

  setMonthAndYear(month: number, year: number): void {
    const obj: DateDto = {
      month,
      year
    };
    return this.whichDateSubject$.next(obj);
  }

  // getMonthAndYear(): DateDto {
  //   return this.whichDateSubject$.getValue();
  // }
}
