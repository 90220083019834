<ng-container *ngIf="{vacation: vacationData$ | async} as O">
  <div class="vacation-bar" *ngIf="O.vacation">

    <ng-container *ngIf="O.vacation.initialMinutes !== 0">
      <div
        class="w_100 total-vac {{O.vacation.remainingMinutes > 0 ? 'gray' : O.vacation.remainingMinutes === 0 ? 'zero' : 'red'}}">
        <!--  [style.height.%]="barItem.remainingMinutes > 0-->
        <div class="received-vac w_{{ O.vacation.remainingMinutes > 0
               ? math.round((O.vacation.initialMinutes - O.vacation.remainingMinutes) / O.vacation.initialMinutes * 100)
               : math.round( (1 - (math.abs(O.vacation.remainingMinutes) / (math.abs(O.vacation.remainingMinutes) + O.vacation.initialMinutes)) ) * 100 ) }}"
        >
          <span>{{ O.vacation.initialMinutes - O.vacation.remainingMinutes | timeConvert}}</span>
        </div>
        <span class="remaining-label" *ngIf="O.vacation.remainingMinutes !== 0 ">
              {{ math.abs(O.vacation.remainingMinutes) | timeConvert }}
          </span>
      </div>

    </ng-container>

    <ng-container *ngIf="O.vacation.initialMinutes === 0">
      <div class="total-vac gray" *ngIf="O.vacation.remainingMinutes === 0; else elseBlock">
      </div>

      <ng-template #elseBlock>
        <div class="total-vac red">
          <span class="remaining-label">{{ math.abs(O.vacation.remainingMinutes) | timeConvert }}</span>
        </div>
      </ng-template>
    </ng-container>

  </div>
</ng-container>
