import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AccessService} from '../../../../../shared/services/access.service';
import {StaffService} from '../../services/staff.service';
import {RoleType} from '../../../../../shared/enums/role-type.enum';
import {GetProfile} from '../../models/get-profile';
import {ToastifyService} from '../../../../../shared/services/toastify.service';
import {DepartmentService} from '../../../departments/services/department.service';
import {TakeLeaveModalComponent} from '../../../vacations/components/take-leave-modal/take-leave-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BloodType} from '../../enums/blood-type';

@UntilDestroy()

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaffProfileComponent implements OnInit {
  routeParams!: string | null;
  imageSrc!: string | null;
  personalProfile = false;
  roleType = RoleType;
  profileData!: GetProfile;
  bloodType = BloodType;
  @ViewChild('imageInput') imageInputVar!: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private staffService: StaffService,
              public accessService: AccessService,
              private toastifyService: ToastifyService,
              private changeDetectorRef: ChangeDetectorRef,
              public departmentService: DepartmentService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.routeParams = this.activatedRoute.snapshot.params.hasOwnProperty('userId') ?
      this.activatedRoute.snapshot.params.userId : null;
    if (this.routeParams) {
      this.personalProfile = this.routeParams === this.accessService.userId;
    } else {
      this.personalProfile = true;
    }
    this.activatedRoute.data
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.profile) {
          this.imageSrc = res.profile.result.imageUrl;
          this.profileData = res.profile.result;
        } else {
          this.router.navigate(['/404']).then();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  selectImage(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.personalProfile) {
        this.staffService.modifyStaffImage(file)
          .pipe(untilDestroyed(this))
          .subscribe(res => {
            if (res.success) {
              this.imageSrc = res.result;
              this.toastifyService.success('تصویر شما با موفقیت تغییر کرد.');
              this.imageInputVar.nativeElement.value = '';
              this.changeDetectorRef.detectChanges();
            }
          });
      }
    }
  }

  removeDepartmentImage(): void {
    this.staffService.removeStaffImage()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.success) {
          this.imageSrc = null;
          this.toastifyService.success('تصویر شما با موفقیت حذف شد.');
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  takeLeave(): void {
    const modal = this.modalService.open(TakeLeaveModalComponent, {centered: true, windowClass: 'take-leave-type'});
    modal.componentInstance.userId = this.routeParams;
  }

  navigateToHistory(): void {
    const url = !this.personalProfile && (this.accessService.roleType !== this.roleType.Employee) ? `vacations/history/${this.routeParams}` : 'vacations/history';
    this.router.navigate([url]).then();
  }

}
