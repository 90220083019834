import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {VacationTypeEnum} from '../../../../../shared/enums/vacation-type.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TeamManagementService} from '../../services/team-management.service';
import {ToastifyService} from '../../../../../shared/services/toastify.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DateTimeConfigInterface} from '../../../../../shared/modules/calendar/models/date-time-config.interface';
import {SingleVacationService} from '../../../vacations/services/single-vacation.service';
import {SingleVacationView} from '../../../vacations/models/single-vacation.view';

@UntilDestroy()
@Component({
  selector: 'app-take-hourly-vacation-as-manager-modal',
  templateUrl: './take-hourly-vacation-as-manager-modal.component.html',
  styleUrls: ['./take-hourly-vacation-as-manager-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TakeHourlyVacationAsManagerModalComponent implements OnInit {
  @Input() userId!: string;
  @Input() vacationId!: string;
  isModifyMode!: boolean;

  hourlyVacationForm!: FormGroup;
  vacationType = VacationTypeEnum;

  dateTimeConfig: DateTimeConfigInterface = {
    hasTime: false,
    hasSeconds: false
  };

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private teamManagementService: TeamManagementService,
              private singleVacationService: SingleVacationService,
              private toastifyService: ToastifyService,
              private ref: ChangeDetectorRef) {

    this.hourlyVacationForm = this.formBuilder.group({
      vacationType: new FormControl(this.vacationType.Hourly),
      vacationDates: new FormControl('', Validators.required),
      userId: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required),
      vacationReason: new FormControl('', [Validators.required, Validators.maxLength(256)])
    });
  }


  ngOnInit(): void {
    this.isModifyMode = !!this.vacationId;
    this.hourlyVacationForm.controls.userId.setValue(this.userId);

    if (this.isModifyMode) {
      this.singleVacationService.getSingleVacation(this.vacationId)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.initialVacationForm(res.result);
        });
    }
  }

  initialVacationForm(result: SingleVacationView): void {
    this.hourlyVacationForm.patchValue({
      vacationReason: result.description,
      vacationDates: result.dates[0],
      startTime: result.from?.slice(0, 5),
      endTime: result.to?.slice(0, 5)
    });
    this.ref.markForCheck();
  }

  patchDatePickerValue(event: string, formControlName: string): void {
    this.hourlyVacationForm.controls[formControlName].patchValue(event);
  }

  convertTime(time: string): string {
    return parseInt(time.slice(0, 2), 10) < 10 ? `0${time}:00` : `${time}:00`;
  }

  modifyHourlyVacation(): void {
    const payload = {
      userId: this.hourlyVacationForm.controls.userId.value,
      type: this.hourlyVacationForm.controls.vacationType.value,
      dates: [Number(this.hourlyVacationForm.controls.vacationDates.value)],
      from: this.convertTime(this.hourlyVacationForm.controls.startTime.value),
      to: this.convertTime(this.hourlyVacationForm.controls.endTime.value),
      alternateUserId: null,
      description: this.hourlyVacationForm.controls.vacationReason.value,
    };

    if (this.isModifyMode) {
      this.teamManagementService.modifyVacationAsSupervisor(this.vacationId, payload)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.toastifyService.success('مرخصی با موفقیت ثبت شد.');
          this.activeModal.dismiss('Cross Click');
        });
    } else {
      this.teamManagementService.getEmployeeVacationAsManager(payload)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.toastifyService.success('مرخصی با موفقیت ثبت شد.');
          this.activeModal.dismiss('Cross Click');
        });
    }

  }

}
