<div class="BarChartContainer">

  <div *ngIf="barChartData.length">
    <ng-container *ngFor="let barItem of barChartData; let i = index;">

      <div class="bar-wrap">
        <ng-container *ngIf="barItem.initialMinutes !== 0">
          <div
            class="bar-item {{barItem.remainingMinutes > 0 ? 'gray' : barItem.remainingMinutes === 0 ? 'zero' : 'red'}}">
            <!--  [style.height.%]="barItem.remainingMinutes > 0-->
            <div class="used-vac h_{{ barItem.remainingMinutes > 0
               ? math.round((barItem.initialMinutes - barItem.remainingMinutes) / barItem.initialMinutes * 100)
               : math.round( (1 - (math.abs(barItem.remainingMinutes) / (math.abs(barItem.remainingMinutes) + barItem.initialMinutes)) ) * 100 ) }}"
            >
              <span>{{ barItem.initialMinutes - barItem.remainingMinutes | timeConvert}}</span>
            </div>
            <span class="remaining-label" *ngIf="barItem.remainingMinutes !== 0 ">
              {{ math.abs(barItem.remainingMinutes) | timeConvert }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="barItem.initialMinutes === 0">
          <div class="bar-item gray" *ngIf="barItem.remainingMinutes === 0; else elseBlock">
          </div>

          <ng-template #elseBlock>
            <div class="bar-item red">
              <span class="remaining-label">{{ math.abs(barItem.remainingMinutes) | timeConvert }}</span>
            </div>
          </ng-template>
        </ng-container>
      </div>

    </ng-container>
  </div>

  <ng-container *ngIf="barChartData.length">
    <div class="hr">
      <ng-container *ngFor="let barItem of barChartData;">
        <span class="month-label" [class.active]="selectedId===barItem.month"
              (click)="chartEventHandler(barItem.month)">{{ barItem.month | months}}</span>
      </ng-container>
    </div>
  </ng-container>

</div>

