import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpEventType, HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {TokenService} from './token.service';
import {HandleErrorService} from './handle-error.service';
import {catchError, map} from 'rxjs/operators';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})

export class HttpClientInterceptor implements HttpInterceptor {

  constructor(private handleError: HandleErrorService,
              private tokenService: TokenService,
              private loadingService: LoadingService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {

          if (event.type === HttpEventType.Sent) {
            const requestMethod: string = request.method;
            if (requestMethod && requestMethod !== 'GET') {
              // console.log('GET-->>loading');
              this.loadingService.setButtonLoading(true);
            }
          }
          if (event instanceof HttpResponse) {
            // if response come
            this.loadingService.setButtonLoading(false);
            if (event.status === 200 && !event.body.success) {
              this.handleError.handle(event.body.errorCode);
              // console.log('here!');
              throw new Error(event.body.errorCode);
            }
          } else {
            // this.mainService.setLoadingStatus(true);
            // console.log('loading');
          }
          return event;
        }),
        catchError((err, caught) => {
          this.loadingService.setButtonLoading(false);
          if (err.status && (err.status === 401 || err.status === 403)) {
            this.tokenService.setIsAuthenticated(false);
            // TODO: Display suitable message!
          }
          if (err.status && (err.status !== 400 && err.status !== 404)) {
            this.handleError.handle(err);
          }
          if (err.status && err.status === 404) {
            this.router.navigate(['/404']).then();
          }

          return throwError(err);
        })
      );
  }
}
