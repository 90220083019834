import {Pipe, PipeTransform} from '@angular/core';
import {monthsEnum} from '../enums/months.enum';

@Pipe({
  name: 'months'
})
export class MonthsPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case monthsEnum.Farvardin:
        return 'فروردين';
      case monthsEnum.Ordibehesht:
        return 'ارديبهشت';
      case monthsEnum.Khordad:
        return 'خرداد';
      case monthsEnum.Tir:
        return 'تير';
      case monthsEnum.Mordad:
        return 'مرداد';
      case monthsEnum.Shahrivar:
        return 'شهريور';
      case monthsEnum.Mehr:
        return 'مهر';
      case monthsEnum.Aban:
        return 'آبان';
      case monthsEnum.Azar:
        return 'آذر';
      case monthsEnum.Dey:
        return 'دی';
      case monthsEnum.Bahman:
        return 'بهمن';
      case monthsEnum.Esfand:
        return 'اسفند';
      default:
        return 'نامشخص';
    }

  }

}
