import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {Observable} from 'rxjs';
import {ResponseModel} from '../../../../shared/models/response-model';
import {DepartmentList} from '../models/department-list';
import {Department} from '../models/department';
import {DepartmentInStaffView} from '../models/department-in-staff-view';
import {AddDepartmentModel} from '../models/add-department-model';
import {UserVacationStoreView} from "../../staff/models/user-vacation-store.view";
import {DailyWorkingHours} from '../models/daily-working-hours';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private apiService: ApiService) {
  }

  departmentsList(): Observable<ResponseModel<DepartmentList[]>> {
    const url = `Department/list`;
    return this.apiService.get(url, true);
  }

  getDepartmentDetails(departmentId: number): Observable<ResponseModel<Department>> {
    const url = `Department/${departmentId}`;
    return this.apiService.get(url, true);
  }

  getDepartmentInStaff(departmentId: number): Observable<ResponseModel<DepartmentInStaffView>> {
    const url = `Department/${departmentId}/items`;
    return this.apiService.get(url, true);
  }

  addDepartmentRole(departmentId: number, title: string): Observable<ResponseModel<number>> {
    const url = `Department/${departmentId}/role`;
    const payload = {
      title
    };

    return this.apiService.post(url, payload, true);
  }

  addDepartment(payload: AddDepartmentModel): Observable<ResponseModel<boolean>> {
    const url = `Department`;
    return this.apiService.post(url, payload, true);
  }

  modifyDepartment(payload: AddDepartmentModel, departmentId: number): Observable<ResponseModel<boolean>> {
    const url = `Department/${departmentId}`;
    return this.apiService.put(url, payload, true);
  }

  removeDepartment(departmentId: number): Observable<ResponseModel<boolean>> {
    const url = `Department/${departmentId}`;
    return this.apiService.delete(url, true);
  }

  modifyDepartmentImage(payload: File, departmentId: number): Observable<ResponseModel<string>> {
    const formData = new FormData();
    formData.append('image', payload);
    const url = `Department/image/${departmentId}`;
    return this.apiService.putFile(url, formData);
  }

  removeDepartmentImage(departmentId: number): Observable<ResponseModel<boolean>> {
    const url = `Department/image/${departmentId}`;
    return this.apiService.delete(url, true);
  }

  getDepartmentUsersVacationStore(departmentId: number, showDebtors: boolean): Observable<ResponseModel<UserVacationStoreView[]>> {
    const url = `department/${departmentId}/staffsVacation?showDebtors=${showDebtors}`;
    return this.apiService.get(url, true);
  }


  groupByDaily(event: DailyWorkingHours[]): any {
    const newGroup = _.groupBy(event, (d) => d.startTime && d.endTime);
    return Object.values(newGroup);
  }

}
