import {Component, Input, OnInit} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {FormBuilder} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() svg!: any;
  @Input() modalText!: any;
  @Input() modalConfirmButton!: any;
  @Input() modalCancelButton!: any;

  constructor(public utilityService: UtilityService,
              public ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.ngbActiveModal.close(true);
  }


}
