import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {VacationsDataSharedView} from '../../models/vacations-data-shared.view';
import {VacationsDataHrView} from '../../../team-management/models/vacations-data-hr.view';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserRoleEnum} from '../../../../../shared/enums/user-role.enum';
import {UserRoleDto} from '../../models/user-role.dto';

@Component({
  selector: 'app-vacation-info-modal',
  templateUrl: './vacation-info-modal.component.html',
  styleUrls: ['./vacation-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VacationInfoModalComponent implements OnInit {
  @Input() vacationItem!: VacationsDataSharedView | VacationsDataHrView;
  @Input() userRole!: UserRoleDto;
  userRoleEnum = UserRoleEnum;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

}
