import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreComponent implements OnInit, DoCheck {
  @Input() isOpen = false;
  openValue!: boolean;
  @Output() showMoreHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.openValue = this.isOpen;
    this.showMoreHandler.emit(this.openValue);
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck(): void {
    if (this.openValue !== this.isOpen) {
      this.openValue = this.isOpen;
      this.showMoreHandler.emit(this.openValue);
      this.changeDetectorRef.detectChanges();
    }
  }

  moreHandler(): void {
    this.openValue = !this.openValue;
    this.showMoreHandler.emit(this.openValue);
    this.changeDetectorRef.detectChanges();
  }
}
