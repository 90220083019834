import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetVacationHistoryFilterService {

  constructor() { }
  resetFilter: BehaviorSubject<any> = new BehaviorSubject(false);
  resetFilter$: Observable<boolean> = this.resetFilter.asObservable();
  resetFilterCall(reset: boolean): void{
    this.resetFilter.next(reset);
  }
}
