import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {ApiService} from '../../../../shared/services/api.service';
import {ResponseModel} from '../../../../shared/models/response-model';
import {PendingVacationView} from '../models/pending-vacation.view';
import {VacationsDataSharedView} from '../../vacations/models/vacations-data-shared.view';
import {VacationsDataHrView} from '../models/vacations-data-hr.view';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CalendarService} from '../../../../shared/components/calendar/calendar.service';
import {MonthRangePickerService} from '../../../../shared/components/month-range-picker/month-range-picker.service';
import {ToastifyService} from '../../../../shared/services/toastify.service';
import {ModalService} from '../../../../shared/services/modal.service';
import {VacationsHistoryView} from '../../../../shared/models/vacations-history.view';
import {AccessService} from '../../../../shared/services/access.service';
import * as moment from 'jalali-moment';

@UntilDestroy()

@Injectable({
  providedIn: 'root'
})

export class TeamManagementService {

  // @ts-ignore
  pendingVacationsListSubject$: BehaviorSubject<VacationsDataSharedView[]> = new BehaviorSubject<VacationsDataSharedView[]>(undefined);
  pendingVacationsItems$: Observable<VacationsDataSharedView[]> = this.pendingVacationsListSubject$.asObservable();

  // @ts-ignore
  handledVacationsListSubject$: BehaviorSubject<VacationsDataHrView[]> = new BehaviorSubject<VacationsDataHrView[]>(undefined);
  handledVacationsItems$: Observable<VacationsDataHrView[]> = this.handledVacationsListSubject$.asObservable();

  constructor(private apiService: ApiService,
              private calendarService: CalendarService,
              private monthRangePickerService: MonthRangePickerService,
              private toastifyService: ToastifyService,
              private modalService: ModalService,
              private accessService: AccessService) {
  }

  setPendingVacationsList(obj: VacationsDataSharedView[]): void {
    return this.pendingVacationsListSubject$.next(obj);
  }

  setHandledVacationsList(obj: VacationsDataHrView[]): void {
    return this.handledVacationsListSubject$.next(obj);
  }

  getPendingVacation(departmentId: number, month: string, year: string): Observable<ResponseModel<PendingVacationView>> {
    const url = `Vacation/pendings/${departmentId}?year=${year}&month=${month}`;
    return this.apiService.get(url, true);
  }

  getHandledVacation(departmentId: number): Observable<ResponseModel<PendingVacationView>> {
    const url = `Vacation/latest/${departmentId}`;
    return this.apiService.get(url, true);
  }

  ModifyVacationStatus(vacationId: number, payload: object): Observable<ResponseModel<boolean>> {
    const url = `Vacation/status/${vacationId}`;
    return this.apiService.put(url, payload, true);
  }

  deleteVacation(vacationId: number, isManager: boolean): Observable<ResponseModel<boolean>> {
    const url = `Vacation/${vacationId}?isManager=${isManager}`;
    return this.apiService.delete(url, true);
  }

  getTeamVacationHistory(departmentId: number, year: string): Observable<ResponseModel<VacationsHistoryView>> {
    const url = `Department/${departmentId}/vacations/history?year=${year}`;
    return this.apiService.get(url, true);
  }

  getTeamVacationHistoryPerMonth(departmentId: number, month: number): Observable<ResponseModel<any>> {
    const year =  moment().locale('fa').format('YYYY');
    const url = `Department/${departmentId}/vacations/history?year=${year}&month=${month}`;
    return this.apiService.get(url, true);
  }

  getEmployeeVacationAsManager(payload: object): Observable<ResponseModel<boolean>> {
    const url = `Vacation`;
    return this.apiService.post(url, payload, true);
  }

  modifyVacationAsSupervisor(vacationId: string, payload: object): Observable<ResponseModel<boolean>> {
    const url = `Vacation/${vacationId}/supervisor`;
    return this.apiService.put(url, payload, true);
  }

  gotoMonth(month: number, year: number): void {
    const departmentId = this.accessService.departmentId;
    this.monthRangePickerService.setMonthAndYear(month, year);
    this.getPendingVacation(departmentId, String(month), String(year))
      .pipe(untilDestroyed(this))
      .subscribe(res => {
          // console.log(res);
          this.calendarService.refactorCalendarHrData(res.result);
          this.setPendingVacationsList(res.result.vacations);
        }
      );
  }

  setVacationStatus(vacationId: number, isConfirmed: boolean): void {
    const body = {
      isConfirmed,
      description: ''
    };
    const temporaryPendingVac = this.pendingVacationsListSubject$.getValue();
    const temporaryHandledVac = this.handledVacationsListSubject$.getValue();

    this.ModifyVacationStatus(vacationId, body)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.toastifyService.success('مرخصی مورد نظر با موفقیت تایید شد!');

        temporaryPendingVac.forEach((value, index) => {
          if (value.id === vacationId) {
            temporaryPendingVac.splice(index, 1);
            this.setPendingVacationsList(temporaryPendingVac);
            const item = {...value, state: 1, status: 2};
            temporaryHandledVac.push(item);
            this.setHandledVacationsList(temporaryHandledVac);
          }
        });
      });
  }

  deletedVacationItem(vacationId: number): void {
    const isManager = true;

    this.modalService.delete('آیا موافق حذف این مرخصی هستید؟')
      .then(res => {
        const temporaryHandledVac = this.handledVacationsListSubject$.getValue();

        this.deleteVacation(vacationId, isManager)
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            if (response.success) {
              this.toastifyService.success('مرخصی با موفقیت حذف شد.');
              temporaryHandledVac.forEach((value, index) => {
                if (value.id === vacationId) {
                  temporaryHandledVac.splice(index, 1);
                  this.setHandledVacationsList(temporaryHandledVac);
                }
              });
            }
          });
      });
  }

}
