<div class="page-title">
  <div class="menu-btn" (click)="mainService.menuHandler(true)">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <h1>{{pageTitle}}</h1>
  <div class="title-logo">
    <a routerLink="['/']">
      <img src="../../../../assets/images/Logo.svg" alt="logo">
    </a>
  </div>
</div>
