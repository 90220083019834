<div class="custom-modal">
  <div class="modal-body">
    <div class="d-flex justify-content-center">
      <ng-container *ngIf="!isManager">
        <a class="vacation-type"
           [routerLink]="['vacations/single']" [queryParams]="{type: 'daily'}"
           (click)="activeModal.dismiss('Cross Click')">
          <app-svg-icon icon="icon-sun"></app-svg-icon>
          <span>روزانه</span>
        </a>

        <a class="vacation-type"
           [routerLink]="['vacations/single']" [queryParams]="{type: 'hourly'}"
           (click)="activeModal.dismiss('Cross Click')">
          <app-svg-icon icon="icon-time"></app-svg-icon>
          <span>ساعتی</span>
        </a>
      </ng-container>

      <ng-container *ngIf="isManager">
        <a class="vacation-type" (click)="takeLeaveAsManager(vacationType.Daily)">
          <app-svg-icon icon="icon-sun"></app-svg-icon>
          <span>روزانه</span>
        </a>

        <a class="vacation-type" (click)="takeLeaveAsManager(vacationType.Hourly)">
          <app-svg-icon icon="icon-time"></app-svg-icon>
          <span>ساعتی</span>
        </a>
      </ng-container>

    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex align-items-center justify-content-center">
      <button class="btn btn-dark-gray" (click)="activeModal.dismiss('Cross Click')">بازگشت</button>
    </div>
  </div>
</div>
