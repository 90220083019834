<div class="select-wrapper"
     (clickOutside)="onClickedOutside()"
     [ngClass]="{'bottom-style': isBottom}"
     #formList>
  <div class="select-label"
       [ngClass]="{'open': isOpen,'active': isActive ,'disabled':disabled}"
       (click)="openHandler()">
    <span class="select-label-placeholder">{{labelText}}</span>
    <span class="select-label-name">{{selectedItem[keyName] !== null ? selectedItem[valueName] : ''}}</span>
    <app-svg-icon icon="icon-arrowDown" class="icon"></app-svg-icon>
    <div class="select-label-active"></div>
  </div>
  <ng-container *ngIf="!disabled">
    <ul class="select-options"
        *ngIf="options.length" [ngClass]="{'open':isOpen}">
      <li *ngFor="let item of options"
          (click)="changeHandler(item)"
          [ngClass]="{'active': item[keyName] === selectedItem[keyName]}">
        {{item[valueName]}}
      </li>

    </ul>
    <div class="select-options-null"
         *ngIf="!options.length"
         [ngClass]="{'open':isOpen}">
      <span>آیتمی جهت نمایش وجود ندارد.</span>
    </div>
  </ng-container>
</div>
