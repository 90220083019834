<div class="custom-modal">
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5>ثبت مرخصی ساعتی برای همکار</h5>
      </div>

      <form class="col-12" [formGroup]="hourlyVacationForm">
        <div class="row">
          <div class="col-12">
            <div class="input-wrapper">
              <app-date-time
                [dateTime]="hourlyVacationForm.controls.vacationDates.value"
                [config]="dateTimeConfig"
                [maxDate]=""
                [texts]="{datePl:'', dateLabel:'تاریخ مرخصی'}"
                (dateTimeChange)="patchDatePickerValue($event, 'vacationDates')"
              ></app-date-time>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-lg-6">
            <div class="input-wrapper">
              <app-input-form
                [labelText]="'ساعت شروع'"
                [inputId]="'tStart-field'"
                formControlName="startTime"
                appFormError
              ></app-input-form>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-6">
            <div class="input-wrapper">
              <app-input-form
                [labelText]="'ساعت پایان'"
                [inputId]="'tEnd-field'"
                formControlName="endTime"
                appFormError
              ></app-input-form>
            </div>
          </div>

          <div class="vacation-reason col-12">
            <p>
              <app-svg-icon icon="icon-writing" class="icon-size-24"></app-svg-icon>
              <span> لطفا دلیل خود را برای ثبت مرخصی را بنویسید.</span>
            </p>
            <label>
              <textarea
                placeholder="به این دلیل که ..."
                formControlName="vacationReason"
                autocomplete="off"
              ></textarea>
            </label>
          </div>

        </div>
      </form>

    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        class="btn btn-green"
        (click)="modifyHourlyVacation()"
        [disabled]="!hourlyVacationForm.valid">
        ثبت مرخصی
      </button>
      <button
        type="button"
        class="btn btn-dark-gray"
        (click)="activeModal.dismiss('Cross Click')">
        بازگشت
      </button>
    </div>
  </div>
</div>
