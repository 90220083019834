import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() {
  }

  password(formControl: FormControl): ValidatorFn {
    const passwordRegEx: RegExp = new RegExp('^(?=^.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)[0-9a-zA-Z!@#$%^&*()]*$');
    let isValid = false;
    let minimumChar = false;
    if (formControl.value.length >= 8) {
      minimumChar = true;
    }
    if (minimumChar && passwordRegEx.test(formControl.value)) {
      isValid = true;
    }
    if (!isValid) {
      return {
        // @ts-ignore
        password: {
          minimumChar,
          valid: false,
        }
      };
    } else {
      // @ts-ignore
      return null;
    }
  }


  // required(skipWhiteSpaceValidation?: any): ValidatorFn {
  //   return (currentControl: AbstractControl): { [p: string]: any } | null => {
  //     let isValid;
  //     if (currentControl.value !== null && currentControl.value !== undefined) {
  //       if (currentControl.value === false || currentControl.value === true) {
  //         isValid = true;
  //       } else {
  //         if (skipWhiteSpaceValidation === true) {
  //           isValid = String(currentControl.value).length > 0;
  //         } else {
  //           isValid = String(currentControl.value).trim().length > 0;
  //         }
  //       }
  //
  //     } else {
  //       isValid = false;
  //     }
  //     if (!isValid) {
  //       return {
  //         required: {
  //           valid: false
  //         }
  //       };
  //     } else {
  //       return null;
  //     }
  //   };
  // }
  //
  // minLength(minLength: number, skipWhiteSpaceValidation?: any): ValidatorFn {
  //   return (currentControl: AbstractControl): { [p: string]: any } | null => {
  //     let isValid;
  //     if (skipWhiteSpaceValidation === true) {
  //       isValid = String(currentControl.value).length >= minLength;
  //     } else {
  //       isValid = String(currentControl.value).trim().length >= minLength;
  //     }
  //     if (!isValid) {
  //       return {
  //         minlength: {
  //           actualLength: skipWhiteSpaceValidation ? String(currentControl.value).length : String(currentControl.value).trim().length,
  //           requiredLength: minLength,
  //           valid: false
  //         }
  //       };
  //     } else {
  //       return null;
  //     }
  //   };
  // }
  //
  // maxLength(maxLength: number, skipWhiteSpaceValidation?: any): ValidatorFn {
  //   return (currentControl: AbstractControl): { [p: string]: any } | null => {
  //     let isValid;
  //     if (skipWhiteSpaceValidation === true) {
  //       isValid = String(currentControl.value).length <= maxLength;
  //     } else {
  //       isValid = String(currentControl.value).trim().length <= maxLength;
  //     }
  //     if (!isValid) {
  //       return {
  //         maxLength: {
  //           actualLength: skipWhiteSpaceValidation ? String(currentControl.value).length : String(currentControl.value).trim().length,
  //           requiredLength: maxLength,
  //           valid: false
  //         }
  //       };
  //     } else {
  //       return null;
  //     }
  //   };
  // }
  //
  // number({min, max}: any): ValidatorFn {
  //   return (currentControl: AbstractControl): { [p: string]: any } | null => {
  //     const value = currentControl.value;
  //     const price = parseInt(currentControl.value, 10);
  //     if (isNaN(price)) {
  //       return {
  //         onlyNumbers: {
  //           valid: false
  //         }
  //       };
  //     } else {
  //       if (price > max) {
  //         return {
  //           inValidMax: {
  //             max,
  //             price,
  //             valid: false
  //           }
  //         };
  //       } else if (price < min) {
  //         return {
  //           inValidMin: {
  //             min,
  //             price,
  //             valid: false
  //           }
  //         };
  //       } else {
  //         return null;
  //       }
  //     }
  //   };
  // }
  //

  pattern(pattern: RegExp): ValidatorFn {
    return (currentControl: AbstractControl): { [p: string]: any } | null => {
      const value = currentControl.value;
      const isValid = pattern.test(value);
      if (isValid) {
        return null;
      } else {
        return {
          pattern: {
            valid: false
          }
        };
      }
    };
  }

  nationalId(formControl: FormControl): ValidatorFn {
    let code = formControl.value;
    const sameNumber = code === '0000000000'
      || code === '1111111111'
      || code === '2222222222'
      || code === '3333333333'
      || code === '4444444444'
      || code === '5555555555'
      || code === '6666666666'
      || code === '7777777777'
      || code === '8888888888'
      || code === '9999999999';
    const valueLength = code ? code.length : 0;
    if (valueLength === 0) {
      // @ts-ignore
      return null;
    }
    if (valueLength < 10 || parseInt(code, 10) === 0 || sameNumber) {
      return {
        // @ts-ignore
        nationalId: {
          valid: false,
        }
      };
    }
    code = ('0000' + code).substr(valueLength + 4 - 10);
    if (parseInt(code.substr(3, 6), 10) === 0) {
      return {
        // @ts-ignore
        nationalId: {
          valid: false,
        }
      };
    }
    const checkCode = parseInt(code.substr(9, 1), 10);
    let result = 0;
    for (let i = 0; i < 9; i++) {
      result += parseInt(code.substr(i, 1), 10) * (10 - i);
    }
    result = result % 11;
    if ((result < 2 && checkCode === result) || (result >= 2 && checkCode === (11 - result))) {
      // @ts-ignore
      return null;
    } else {
      return {
        // @ts-ignore
        nationalId: {
          valid: false,
        }
      };
    }
  }
}
