import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ValidatorsService} from '../../services/validators.service';

@Component({
  selector: 'app-one-input-modal',
  templateUrl: './one-input-modal.component.html',
  styleUrls: ['./one-input-modal.component.scss'],
  providers: [FormBuilder]
})
export class OneInputModalComponent implements OnInit {

  @Input() svg: any | undefined;
  @Input() modalHeader: any | undefined;
  @Input() modalText: any | undefined;
  @Input() modalConfirmButton: any | undefined;
  @Input() modalCancelButton: any | undefined;
  @Input() initialValue: any | undefined;
  @Input() inputConf: { placeholder?: string, maxLength?: number, minLength?: null, type?: string } | undefined;

  modalForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              public ngbActiveModal: NgbActiveModal,
              private validatorsService: ValidatorsService) {
    this.modalForm = this.formBuilder.group({
      text: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    if (this.initialValue !== undefined) {
      this.modalForm.controls.text.patchValue(this.initialValue);
    }
  }

  onSubmit(): void {
    this.ngbActiveModal.close(this.modalForm.controls.text.value);
  }

}
