import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TeamManagementService} from '../../../services/team-management.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ToastifyService} from '../../../../../../shared/services/toastify.service';
import {Observable} from 'rxjs';
import {VacationsDataSharedView} from '../../../../vacations/models/vacations-data-shared.view';

@UntilDestroy()

@Component({
  selector: 'app-reject-vacation-modal',
  templateUrl: './reject-vacation-modal.component.html',
  styleUrls: ['./reject-vacation-modal.component.scss']
})
export class RejectVacationModalComponent implements OnInit {

  @Input() vacationId!: number;
  pendingVacationsItems$!: Observable<VacationsDataSharedView[]>;
  rejectionReason!: string;

  constructor(public activeModal: NgbActiveModal,
              private teamManagementService: TeamManagementService,
              private toastifyService: ToastifyService) {
  }

  ngOnInit(): void {
  }

  onSubmit(form: any): void {
    // console.log(form.form.valid);

    if (form.controls.rejectionReason.value && form.form.valid) {
      // console.log(form.controls.rejectionReason.value);
      const body = {
        isConfirmed: false,
        description: form.controls.rejectionReason.value
      };
      this.teamManagementService.ModifyVacationStatus(this.vacationId, body)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          const temporaryPendingVac = this.teamManagementService.pendingVacationsListSubject$.getValue();
          temporaryPendingVac.forEach((value, index) => {
            if (value.id === this.vacationId) {
              temporaryPendingVac.splice(index, 1);
              this.teamManagementService.setPendingVacationsList(temporaryPendingVac);
            }
          });
          this.activeModal.dismiss('Cross Click');
          this.toastifyService.success('مرخصی مورد نظر رد شد!');
        });
    }


  }
}
