import {Component, Input, OnInit} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {MainService} from '../../../main/main.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  pageTitle!: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public mainService: MainService) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .pipe(
        map(() => this.activatedRoute),
        map(route => {
          const allComponents: ActivatedRouteSnapshot[] = [];
          while (route) {
            if (!!(route.component) && !!(route.snapshot.data.title)) {
              allComponents.push(route.snapshot);
            }
            // @ts-ignore
            route = route.firstChild;
          }
          return allComponents;
        }),
        map((res) => {
          return res.map(i => i.data.title);
        })
      )
      .subscribe((res) => {
        this.pageTitle = res.length > 0 ? `${res[res.length - 1]}` : 'نیان';
      });
  }

  ngOnInit(): void {
  }

}
