import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FilterItem} from '../../models/filter-item';

@Component({
  selector: 'app-select-form',
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFormComponent implements OnInit, AfterContentChecked {
  @Input() labelText!: string;
  @Input() options: any[] = [];
  @Input() valueName = 'value';
  @Input() keyName = 'key';
  @Input() defaultKey: any = null;
  @Input() disabled = false;

  @Output() changeSelect: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('formList') list!: ElementRef;

  isOpen = false;
  isActive = false;
  selectedItem: any;
  isBottom = false;
  listMinHeight = 250;

  constructor(private detection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initHandler();
  }

  ngAfterContentChecked(): void {
    this.initHandler();
  }

  initHandler(): void {
    const hasActive = this.options.find(f => f[this.keyName] === this.defaultKey);
    if (hasActive) {
      this.changeSelect.emit(hasActive[this.keyName] === null ? null : hasActive);
      this.selectedItem = {...hasActive};
      this.isActive = hasActive[this.keyName] !== null;
    } else {
      this.isActive = false;
      this.selectedItem = {
        [this.valueName]: '',
        [this.keyName]: null
      };
      this.changeSelect.emit(null);
    }
  }

  changeHandler(event: any): void {
    this.selectedItem = {...event};
    this.isOpen = false;
    if (event[this.keyName] !== null) {
      this.isActive = true;
      this.changeSelect.emit(event);
    } else {
      this.isActive = false;
      this.changeSelect.emit(event[this.keyName]);
    }
  }

  openHandler(): void {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
    if (this.isOpen) {
      const listScrollHeight = (this.list.nativeElement.children[1].scrollHeight > this.listMinHeight
        ? this.listMinHeight
        : this.list.nativeElement.children[1].scrollHeight) + this.list.nativeElement.children[1].offsetTop;

      const elementLocation = window.innerHeight - listScrollHeight;
      this.isBottom = elementLocation < this.list.nativeElement.getBoundingClientRect().top;
    }
  }

  onClickedOutside(): void {
    this.isOpen = false;
  }

}
