import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeConvert'
})

export class TimeConvertPipe implements PipeTransform {
  transform(value: any): any {
    const hours = Math.floor(value / 60);
    const minutes = value - (hours * 60);
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }
}
