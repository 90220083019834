import {ApiService} from '../../../../shared/services/api.service';
import {Observable} from 'rxjs';
import {ResponseModel} from '../../../../shared/models/response-model';
import {AddOrModifyStaffModel} from '../models/add-staff.model';
import {Injectable} from '@angular/core';
import {StaffView} from '../models/staff-view';
import {ClaimType} from '../enums/claims';
import {ClaimDto} from '../models/claim.dto';
import {MilitaryStatus} from '../enums/military-status';
import {BloodType} from '../enums/blood-type';
import {BloodTypeDto} from '../models/blood-type.dto';
import {MilitaryStatusDto} from '../models/military-status.dto';
import {EducationDegreeDto} from '../models/education-degree.dto';
import {EducationDegree} from '../enums/education-degree';
import {MBTIDto} from '../models/mbti.dto';
import {MBTI} from '../enums/MBTI';
import {SummaryStaffView} from '../models/summary-staff.view';
import {GetProfile} from '../models/get-profile';
import {VacationBalanceMinute} from '../../departments/models/vacation-balance-minute';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  get vacationBalanceMinute(): VacationBalanceMinute[] {
    return this.VacationBalanceMinute;
  }

  constructor(private apiService: ApiService) {
  }

  private VacationBalanceMinute = [
    {key: '00', value: '00'},
    {key: '15', value: '15'},
    {key: '30', value: '30'},
    {key: '45', value: '45'},
  ];

  addStaff(model: AddOrModifyStaffModel): Observable<ResponseModel<boolean>> {
    const url = `Staff`;
    return this.apiService.post<AddOrModifyStaffModel>(url, model, true);
  }

  activationStaff(payload: boolean, id: string): Observable<ResponseModel<boolean>> {
    const url = `Staff/activation/${id}`;
    return this.apiService.patch(url, payload, true);
  }

  modifyStaff(id: string | null, model: AddOrModifyStaffModel): Observable<ResponseModel<boolean>> {
    const url = `Staff/${id}`;
    return this.apiService.put<AddOrModifyStaffModel>(url, model, true);
  }

  getStaff(staffId: string | null): Observable<ResponseModel<StaffView>> {
    const url = `Staff/${staffId}`;
    return this.apiService.get(url, true);
  }

  getAllStaffs(phrase: string, isActive: boolean): Observable<ResponseModel<SummaryStaffView[]>> {
    const url = `Organization/staffs?phrase=${phrase}&isActive=${isActive}`;
    return this.apiService.get(url, true);
  }

  getProfile(userId: string): Observable<ResponseModel<GetProfile>> {
    const url = `Staff/${userId}/profile`;
    return this.apiService.get(url, true);
  }

  checkUsername(username: string): Observable<ResponseModel<boolean>> {
    const params = {
      username
    };
    const url = `Staff/checkusername`;
    return this.apiService.get(url, true, params);
  }

  modifyStaffImage(payload: File): Observable<ResponseModel<string>> {
    const formData = new FormData();
    formData.append('image', payload);
    const url = `Staff/profile/image`;
    return this.apiService.putFile(url, formData);
  }

  removeStaffImage(): Observable<ResponseModel<boolean>> {
    const url = `Staff/profile/image`;
    return this.apiService.delete(url, true);
  }

  getClaims(): ClaimDto[] {
    const claims: ClaimDto[] = [{
      type: ClaimType.CanGiveOrTakeBackClaims,
      description: 'اعطا یا پس گرفتن دسترسی'
    }, {
      type: ClaimType.ModifyOrConfirmVacations,
      description: 'تایید یا رد مرخصی ها',
    }, {
      type: ClaimType.ConfirmCEOVacations,
      description: 'تایید مرخصی مدیرعامل'
    }];
    return claims;
  }

  getMilitaryStatuses(): MilitaryStatusDto[] {
    const statuses: MilitaryStatusDto[] = [{
      value: MilitaryStatus.Exempt,
      description: 'معاف'
    }, {
      description: 'معافیت موقت',
      value: MilitaryStatus.TemporaryExempt
    }, {
      value: MilitaryStatus.HasCertificate,
      description: 'پایان خدمت'
    }, {
      description: 'سرباز',
      value: MilitaryStatus.Conscript
    }];

    return statuses;
  }

  getBloodTypes(): BloodTypeDto[] {
    const bloodTypes: BloodTypeDto[] = [];

    const bloodTypesAsArray = Object.keys(BloodType).filter(key => isNaN(Number(key)));

    bloodTypesAsArray.forEach(x => {

      const bloodType: BloodType = BloodType[x as keyof typeof BloodType];

      const item: BloodTypeDto = {
        description: x,
        value: bloodType
      };
      bloodTypes.push(item);
    });

    return bloodTypes;
  }

  getMBTITypes(): MBTIDto[] {
    const mbtiTypes: MBTIDto[] = [];

    const mbtiTypesAsArray = Object.keys(MBTI).filter(key => isNaN(Number(key)));

    mbtiTypesAsArray.forEach(x => {

      const mbti: MBTI = MBTI[x as keyof typeof MBTI];

      const item: MBTIDto = {
        description: x,
        value: mbti
      };
      mbtiTypes.push(item);
    });

    return mbtiTypes;
  }

  getEducationDegrees(): EducationDegreeDto[] {
    const degrees: EducationDegreeDto[] = [
      {
        value: EducationDegree.UnderDiploma,
        description: 'زیر دیپلم'
      }, {
        value: EducationDegree.Dimploma,
        description: 'دیپلم'
      }, {
        value: EducationDegree.Associate,
        description: 'فوق دیپلم'
      }, {
        value: EducationDegree.Bachelor,
        description: 'لیسانس'
      }, {
        value: EducationDegree.Master,
        description: 'فوق لیسانس'
      }, {
        value: EducationDegree.Doctoral,
        description: 'دکترا'
      }, {
        value: EducationDegree.PostDoctoral,
        description: 'فوق دکترا'
      }
    ];

    return degrees;
  }
}
