import {Pipe, PipeTransform} from '@angular/core';
import {UtilityService} from '../../../../shared/services/utility.service';

@Pipe({
  name: 'province'
})
export class ProvincePipe implements PipeTransform {
  constructor(private utilityService: UtilityService) {
  }

  transform(value: number): string {
    const item = this.utilityService.province.find(f => f.value === value);
    return item && item.hasOwnProperty('name') ? item.name : '';
  }

}
