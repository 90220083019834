import {Pipe, PipeTransform} from '@angular/core';
import {BloodType} from '../enums/blood-type';

@Pipe({
  name: 'bloodTypePipe'
})
export class BloodTypePipe implements PipeTransform {
  bloodTypeList = BloodType;

  transform(value: BloodType): string {
    return this.bloodTypeList[value] ? this.bloodTypeList[value] : '';
  }

}
