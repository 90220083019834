import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ApiService} from '../../../../shared/services/api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ResponseModel} from '../../../../shared/models/response-model';
import {SingleVacationView} from '../models/single-vacation.view';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SingleVacationService {

  // @ts-ignore
  // vacationTimeSubject$: BehaviorSubject<VacationDurationView> = new BehaviorSubject<VacationDurationView>(undefined);
  // vacationTime$: Observable<VacationDurationView> = this.vacationTimeSubject$.asObservable();

  emergencyReasonSubject$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  emergencyReason$: Observable<number | null> = this.emergencyReasonSubject$.asObservable();

  constructor(private apiService: ApiService) {
  }

  getSingleVacation(vacationId: string): Observable<ResponseModel<SingleVacationView>> {
    const url = `Vacation/${vacationId}`;
    return this.apiService.get(url, true);
  }

  modifySingleVacation(vacationId: string, payload: object): Observable<ResponseModel<boolean>> {
    const url = `Vacation/${vacationId}`;
    return this.apiService.put(url, payload, true);
  }

  deSelected($event: any): void {
    $event.currentTarget.classList.remove('selected');
  }

  setEmergencyReason(object: number | null): void {
    this.emergencyReasonSubject$.next(object);
  }

}
