<div class="custom-modal">
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h5>ثبت مرخصی روزانه برای همکار</h5>
      </div>

      <form class="col-12" [formGroup]="dailyVacationForm">
        <div class="row">
          <div class="col-12" formArrayName="vacationDates">
            <div class="input-wrapper" *ngFor="let alias of vacationDateFormGroup.controls; let i=index">
              <app-date-time
                [dateTime]="alias.value"
                [config]="dateTimeConfig"
                [maxDate]=""
                [texts]="{datePl:'', dateLabel:'تاریخ مرخصی'}"
                (dateTimeChange)="patchDatePickerValue($event, i)"
              ></app-date-time>
            </div>
            <span class="plus btn btn-cyan" (click)="addMoreInput()"> + افزودن تاریخ </span>

          </div>

          <div class="vacation-reason col-12">
            <p>
              <app-svg-icon icon="icon-writing" class="icon-size-24"></app-svg-icon>
              <span> لطفا دلیل خود را برای ثبت مرخصی را بنویسید.</span>
            </p>
            <label>
              <textarea
                placeholder="به این دلیل که ..."
                formControlName="vacationReason"
                autocomplete="off"
              ></textarea>
            </label>
          </div>

        </div>
      </form>

    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        class="btn btn-green"
        (click)="modifyDailyVacation()"
        [disabled]="!dailyVacationForm.valid">
        ثبت مرخصی
      </button>
      <button
        type="button"
        class="btn btn-dark-gray"
        (click)="activeModal.dismiss('Cross Click')">
        بازگشت
      </button>
    </div>
  </div>
</div>
