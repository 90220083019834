import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {VacationTypeEnum} from '../../enums/vacation-type.enum';
import {VacationStatusEnum} from '../../enums/vacation-status.enum';
import {UserRoleDto} from '../../../main/modules/vacations/models/user-role.dto';
import {UserRoleEnum} from '../../enums/user-role.enum';
import {VacationStateEnum} from '../../enums/vacation-state.enum';
import {VacationsDataSharedView} from '../../../main/modules/vacations/models/vacations-data-shared.view';
import {VacationsDataHrView} from '../../../main/modules/team-management/models/vacations-data-hr.view';
import {UtilityService} from '../../services/utility.service';
import {TeamManagementService} from '../../../main/modules/team-management/services/team-management.service';
import {untilDestroyed} from '@ngneat/until-destroy';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {VacationInfoModalComponent} from '../../../main/modules/vacations/components/vacation-info-modal/vacation-info-modal.component';
import {VacationsService} from '../../../main/modules/vacations/services/vacations.service';
import {Router} from '@angular/router';
import {RejectVacationModalComponent} from '../../../main/modules/team-management/components/vacation-management/reject-vacation-modal/reject-vacation-modal.component';
import {TakeDailyVacationAsManagerModalComponent} from '../../../main/modules/team-management/components/take-daily-vacation-as-manager-modal/take-daily-vacation-as-manager-modal.component';
import {TakeHourlyVacationAsManagerModalComponent} from '../../../main/modules/team-management/components/take-hourly-vacation-as-manager-modal/take-hourly-vacation-as-manager-modal.component';
import {MonthRangePickerService} from '../month-range-picker/month-range-picker.service';

@Component({
  selector: 'app-vacation-item',
  templateUrl: './vacation-item.component.html',
  styleUrls: ['./vacation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VacationItemComponent implements OnInit {

  @Input() vacationItem!: VacationsDataSharedView | VacationsDataHrView;
  @Input() userRoleId!: UserRoleDto;

  VacationType = VacationTypeEnum;
  VacationStatus = VacationStatusEnum;
  VacationState = VacationStateEnum;
  UserRole = UserRoleEnum;
  userVacationState!: VacationStateEnum;

  private dates: string[] = [];
  presentableDate!: string;
  visibility!: boolean;

  constructor(private utilityService: UtilityService,
              private teamManagementService: TeamManagementService,
              private monthRangePickerService: MonthRangePickerService,
              private modalService: NgbModal,
              private vacationsService: VacationsService,
              private modal: NgbModal,
              private router: Router) {
  }

  ngOnInit(): void {
    this.userVacationState = ('state' in this.vacationItem) ? this.vacationItem.state : 0;
    this.dates = this.vacationItem.dates.split(',');

    if (this.dates.length > 1) {
      const arrayOfDates = [];
      for (const tempDateElement of this.dates) {
        arrayOfDates.push(this.utilityService.convertStringToPresentableDate(tempDateElement));
      }
      this.presentableDate = arrayOfDates.join(' - ');
      // this.presentableDate = this.utilityService.convertStringToPresentableDate(this.dates[0])
      //   .concat(' - ', this.utilityService.convertStringToPresentableDate(this.dates[this.dates.length - 1]));
    }
    if (this.dates.length === 1) {
      this.presentableDate = this.utilityService.convertStringToPresentableDate(this.dates[0]);
    }

    this.visibility = !!(this.vacationItem.description || this.vacationItem.emergencyReason || this.vacationItem.alternativeFullName || this.vacationItem.supervisorDescription);
  }

  ModifyStatusVacation(vacationId: number, isConfirmed: boolean): void {
    if (isConfirmed) {
      this.teamManagementService.setVacationStatus(vacationId, isConfirmed);
    } else {
      const modalRef = this.modal.open(RejectVacationModalComponent, {centered: true});
      modalRef.componentInstance.vacationId = vacationId;
    }
  }

  showInfo(vacationItem: any): void {
    const modalRef = this.modalService.open(VacationInfoModalComponent, {centered: true});
    modalRef.componentInstance.vacationItem = vacationItem;
    modalRef.componentInstance.userRole = this.userRoleId;
  }

  deletedVacationAsUser(vacationId: number): void {
    this.vacationsService.deletedVacationItem(vacationId);
  }

  deletedVacationAsHr(vacationId: number): void {
    this.teamManagementService.deletedVacationItem(vacationId);
  }

  modifyVacation(vacationItem: any): void {
    this.dates = vacationItem.dates.split(',');
    const selectedMonth = Number(this.dates[0].slice(4, 6));
    const selectedYear = Number(this.dates[0].slice(0, 4));

    sessionStorage.setItem('selectedMonth', String(selectedMonth));
    sessionStorage.setItem('selectedYear', String(selectedYear));

    // this.monthRangePickerService.setMonthAndYear(selectedMonth, selectedYear);

    const vacationType = (vacationItem.type === VacationTypeEnum.Daily) ? 'daily' : 'hourly';
    this.router.navigate(['vacations/single/', vacationItem.id], {queryParams: {type: vacationType}}).then();
  }

  modifyVacationAsSupervisor(vacationItem: any): void {
    let modal: NgbModalRef;

    if (vacationItem.type === VacationTypeEnum.Daily) {
      modal = this.modalService.open(TakeDailyVacationAsManagerModalComponent, {centered: true, windowClass: 'take-leave-asManager'});
    } else {
      modal = this.modalService.open(TakeHourlyVacationAsManagerModalComponent, {centered: true, windowClass: 'take-leave-asManager'});
    }
    modal.componentInstance.userId = this.vacationItem.userId;
    modal.componentInstance.vacationId = this.vacationItem.id;
  }
}
