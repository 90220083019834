import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AccessService} from '../../../../shared/services/access.service';
import {GetProfile} from '../models/get-profile';
import {RoleType} from '../../../../shared/enums/role-type.enum';
import {map} from 'rxjs/operators';
import {StaffService} from './staff.service';
import {ResponseModel} from '../../../../shared/models/response-model';

@Injectable({
  providedIn: 'root'
})
export class GetProfileResolver implements Resolve<ResponseModel<GetProfile> | null> {
  roleType = RoleType;

  constructor(private accessService: AccessService,
              private staffService: StaffService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResponseModel<GetProfile>> | null {
    const routeParams = route.params.hasOwnProperty('userId') ? route.params.userId : null;
    let personalProfile = false;
    if (routeParams) {
      personalProfile = routeParams === this.accessService.userId;
    } else {
      personalProfile = true;
    }
    if (personalProfile ||
      (this.accessService.roleType === this.roleType.CEO || this.accessService.roleType === this.roleType.HR)) {
      return this.staffService.getProfile(routeParams ? routeParams : this.accessService.userId)
        .pipe(
          map((response) => {
            return response;
          })
        );
    } else {
      return null;
    }
  }
}
