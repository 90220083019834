export class RefactorCalendarListDto {
  constructor(day: number, dayOfWeek: number, holidaysEvent: string, status: number | null, hourlyCount?: number | undefined, dailyCount?: number | undefined) {
    this.day = day ? day : 0;
    this.dayOfWeek = dayOfWeek;
    this.holidaysEvent = holidaysEvent ? holidaysEvent : null;
    this.vacationStatus = status;
    this.hourlyVacationCount = hourlyCount !== undefined ? hourlyCount : null;
    this.dailyVacationCount = dailyCount !== undefined ? dailyCount : null;
  }

  day: number;
  dayOfWeek: number;
  holidaysEvent: null | string;
  vacationStatus: null | number;
  hourlyVacationCount: null | number;
  dailyVacationCount: null | number;

}
