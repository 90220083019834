import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ToggleSwitchService {

  private toggleSwitchStatusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSelectedToggleSwitch$: Observable<boolean> = this.toggleSwitchStatusSubject$.asObservable();

  constructor() {
  }

  setToggleSwitchStatus(status: boolean): void {
    return this.toggleSwitchStatusSubject$.next(status);
  }

  getCurrentValue(): boolean {
    return this.toggleSwitchStatusSubject$.getValue();
  }
}
