import {Injectable} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  get BaseUrl(): string {
    return this.baseUrl;
  }

  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = 'https://back.niyan.ipe.ir';
    // this.baseUrl = 'https://ibackend.whitesms.net';

    // this.baseUrl = 'https://back.vac2.ipe.ir';
    // this.baseUrl = 'https://localhost:44302';
    // this.baseUrl = 'https://localhost:5001';
  }

  private $modalOptions: NgbModalOptions = {
    centered: true,
    windowClass: 'm-modal',
    scrollable: true
  };

  public modalOptions(size?: any): any {
    if (size) {
      return {
        centered: true,
        size
      };
    } else {
      return this.$modalOptions;
    }
  }
}
