<div class="toggle-switch" [style.width.px]="width" *ngIf="{isSelected: isSelected$ | async} as O">
  <label class="toggle-input">
    <input type="checkbox" [ngModel]="O.isSelected" (input)="setValue($event)">
    <span class="toggle-handle"></span>
    <span class="item toggle-on">
      {{switchLabel.on}}
    </span>
    <span class="item toggle-off">
      {{switchLabel.off}}
    </span>
  </label>
</div>
