import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {VacationTypeEnum} from '../../../../../shared/enums/vacation-type.enum';
import {DateTimeConfigInterface} from '../../../../../shared/modules/calendar/models/date-time-config.interface';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TeamManagementService} from '../../services/team-management.service';
import {ToastifyService} from '../../../../../shared/services/toastify.service';
import {VacationsDataHrView} from '../../models/vacations-data-hr.view';
import {SingleVacationService} from '../../../vacations/services/single-vacation.service';
import {SingleVacationView} from '../../../vacations/models/single-vacation.view';

@UntilDestroy()
@Component({
  selector: 'app-take-daily-vacation-as-manager-modal',
  templateUrl: './take-daily-vacation-as-manager-modal.component.html',
  styleUrls: ['./take-daily-vacation-as-manager-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeDailyVacationAsManagerModalComponent implements OnInit {
  @Input() userId!: string;
  @Input() vacationId!: string;
  isModifyMode!: boolean;

  dailyVacationForm!: FormGroup;
  vacationType = VacationTypeEnum;

  dateTimeConfig: DateTimeConfigInterface = {
    hasTime: false,
    hasSeconds: false
  };

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private teamManagementService: TeamManagementService,
              private singleVacationService: SingleVacationService,
              private toastifyService: ToastifyService,
              private ref: ChangeDetectorRef) {

    this.dailyVacationForm = this.formBuilder.group({
      vacationType: new FormControl(this.vacationType.Daily),
      vacationDates: this.formBuilder.array([
        this.formBuilder.control('', Validators.required),
      ]),
      userId: new FormControl('', Validators.required),
      vacationReason: new FormControl('', [Validators.required, Validators.maxLength(256)])
    });
  }

  ngOnInit(): void {
    this.isModifyMode = !!this.vacationId;
    this.dailyVacationForm.controls.userId.setValue(this.userId);

    if (this.isModifyMode) {
      this.singleVacationService.getSingleVacation(this.vacationId)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.initialVacationForm(res.result);
        });
    }
  }

  initialVacationForm(result: SingleVacationView): void {
    this.dailyVacationForm.controls.vacationReason.patchValue(result.description);
    const vacationDates = result.dates;
    this.ref.markForCheck();

    if (vacationDates.length > 1) {
      this.patchDatePickerValue(String(vacationDates[0]), 0);
      for (let i = 1; i <= vacationDates.length - 1; i++) {
        this.vacationDateFormGroup.push(new FormControl(String(vacationDates[i])));
      }
    } else {
      this.patchDatePickerValue(String(vacationDates[0]), 0);
    }
  }

  patchDatePickerValue(event: string, i: number): void {
    // console.log(event);
    const targetControl = this.dailyVacationForm.controls.vacationDates;
    // @ts-ignore
    targetControl.controls[i].patchValue(Number(event));
  }

  get vacationDateFormGroup(): FormArray {
    return this.dailyVacationForm.get('vacationDates') as FormArray;
  }

  addMoreInput(): void {
    this.vacationDateFormGroup.push(new FormControl(''));
  }

  modifyDailyVacation(): void {
    const payload = {
      userId: this.dailyVacationForm.controls.userId.value,
      type: this.dailyVacationForm.controls.vacationType.value,
      dates: this.dailyVacationForm.controls.vacationDates.value,
      alternateUserId: null,
      description: this.dailyVacationForm.controls.vacationReason.value,
    };

    if (this.isModifyMode) {
      this.teamManagementService.modifyVacationAsSupervisor(this.vacationId, payload)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.toastifyService.success('مرخصی با موفقیت ثبت شد.');
          this.activeModal.dismiss('Cross Click');
        });

    } else {
      this.teamManagementService.getEmployeeVacationAsManager(payload)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.toastifyService.success('مرخصی با موفقیت ثبت شد.');
          this.activeModal.dismiss('Cross Click');
        });
    }

  }

}
