import {InjectionToken} from '@angular/core';

export const defaultErrors = {
  required: () => `پر کردن این فیلد الزامیست.`,
  minlength: ({requiredLength}: any) => `حداقل ${requiredLength} کارکتر وارد وارد شود`,
  maxlength: ({requiredLength}: any) => `حداکثر ${requiredLength} کارکتر وارد وارد شود`,
  incorrectMailFormat: () => `فرمت ایمیل اشتباه است.`,
  onlyNumbers: () => `تنها مجاز به نوشتن اعداد هستید`,
  password: ({minimumChar}: any) => !minimumChar ? `رمز شما باید بیشتر از 8 رقم باشد.` : `رمز شما باید شامل حرف بزرگ و کوچک، عدد باشد.`,
  pattern: () => 'فرمت مقدار وارد شده اشتباه است.',
  nationalId: () => 'کد ملی معتبر نیست'
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
