import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ResetVacationHistoryFilterService} from '../../../../services/reset-vacation-history-filter.service';

@Component({
  selector: 'app-filter-table-item',
  templateUrl: './filter-table-item.component.html',
  styleUrls: ['./filter-table-item.component.scss']
})
export class FilterTableItemComponent implements OnInit {
  showSubMenu = false;
  selectedItem = 0;
  activeFilter = false;
  @Input() filterConfig: any;
  @Output() selectedValue = new EventEmitter();
  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void{
    if (! this.elementRef.nativeElement.contains(event.target)){
      this.showSubMenu = false;
    }
  }
  constructor(
    private elementRef: ElementRef,
    private resetFilterService: ResetVacationHistoryFilterService
  ) { }

  ngOnInit(): void {
    this.resetFilterService.resetFilter$
        .subscribe(restetStatus => {
          if (restetStatus){
            this.resetFilter();
          }
        });
  }
  toggleShowSubMenu(): void{
    this.showSubMenu = !this.showSubMenu;
  }
  changeFilterItem(newSelectedItem: number): void{

    if (this.selectedItem !== newSelectedItem){
      this.selectedItem = newSelectedItem;
      this.showSubMenu = false;
      this.selectedItem !== 0 ? this.activeFilter = true : this.activeFilter = false;
      this.selectedValue.emit(
        {
          [this.filterConfig.value] : this.filterConfig.filterData[newSelectedItem].value
        }
      );

    }
  }
  resetFilter(): void{
    this.changeFilterItem(0);
  }
}
