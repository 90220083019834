import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableServiceService {

  constructor() { }
  filterInfo: Subject<any> = new Subject();
  filterInfo$: Observable<any> = this.filterInfo.asObservable();
}
