import {Pipe, PipeTransform} from '@angular/core';
import {UtilityService} from '../services/utility.service';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {
  constructor(private utilityService: UtilityService) {
  }

  transform(value: number): string {
    // @ts-ignore
    return this.utilityService.weekday.find(f => f.value === value).name;
  }
}
