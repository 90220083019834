import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {DateTimeConfigInterface} from '../../models/date-time-config.interface';
import {NgbCalendar, NgbCalendarPersian, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {NgbDatepickerI18nPersian} from '../../services/ngb-datepicker-i18n-persian.service';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NgbCalendar, useClass: NgbCalendarPersian},
    {provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nPersian}
  ],
})
export class DateTimeComponent implements OnInit, OnChanges {


  @ViewChild('datePickerDropDown', {static: false}) datePickerDropDown!: ElementRef;
  @Input() maxDate: any;
  @Input() minDate: any;
  @Input() isCancelBtn = true;
  $minDate: any;
  $maxDate: any;
  @Input() texts!: { timeLabel?: string, dateLabel?: string, datePl?: string };
  @Input() dateTime!: string;
  @Output() dateTimeChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() config!: DateTimeConfigInterface;
  date!: { day: any, month: any, year: any };
  timeError: null;
  time!: { hour: any, minute: any, second: any };
  @Input() errorMsgLess!: boolean;

  constructor(private utilityService: UtilityService) {

  }

  ngOnInit(): void {
    this.updateView();
  }

  updateView(): void {
    if (!!this.dateTime) {
      this.date = this.utilityService.convertStringToDateObject(this.dateTime);
      if (this.config.hasTime) {
        this.time = this.utilityService.convertStringToTimeObject(this.dateTime);
      }
    } else {
      this.date = {day: '', month: '', year: ''};
      this.time = {second: null, hour: null, minute: null};
    }
  }


  dateChanged(dropDown: any, date: any): void {
    this.date = date;
    let emittedData: any;
    if (this.date) {
      emittedData = (!this.date.day || !this.date.month || !this.date.year) ?
        null :
        this.utilityService.convertDateObjectToString(this.date);
      if (this.config.hasTime && (!!this.time.minute && !!this.time.hour && !!this.time.second)) {
        emittedData = emittedData + this.utilityService.convertTimeObjectToString(this.time);
      }
      this.dateTimeChange.emit(emittedData);
      dropDown.close();
    }
  }


  timeChanged(): void {
    let emittedData: string;
    if (this.date) {
      emittedData = this.utilityService.convertDateObjectToString(this.date);
      if (this.time) {
        emittedData = this.utilityService.convertDateObjectToString(this.date) + this.utilityService.convertTimeObjectToString(this.time);
      }
      this.dateTimeChange.emit(emittedData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateTime !== undefined && (changes.dateTime.currentValue !== changes.dateTime.previousValue))) {
      this.updateView();
    }
    if ((changes.minDate !== undefined && (changes.minDate.currentValue !== changes.minDate.previousValue))) {
      this.$minDate = this.utilityService.convertStringToDateObject(changes.minDate.currentValue);
    }
    if ((changes.maxDate !== undefined && (changes.maxDate.currentValue !== changes.maxDate.previousValue))) {
      this.$maxDate = this.utilityService.convertStringToDateObject(changes.maxDate.currentValue);
    }
  }

  clearDate($event: MouseEvent): void {
    this.date = {year: '', month: '', day: ''};
    // @ts-ignore
    this.dateTimeChange.emit(null);
  }
}
