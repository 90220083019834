import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {BehaviorSubject, Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {LoginResult} from '../models/login-result';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private _ACCESS_TOKEN = 'access_token';
  private _REFRESH_TOKEN = 'refresh_token';
  private isAuthenticated!: BehaviorSubject<boolean> ;
  isAuthenticated$: Observable<boolean>;

  constructor(private cookieService: CookieService) {
    this.isAuthenticated = new BehaviorSubject<boolean>(this.hasAccessToken() && !this.isTokenExpired());
    this.isAuthenticated$ = this.isAuthenticated.asObservable();
  }

  setTokenItems(loginResult: LoginResult): void {
    this.cookieService.set(this._ACCESS_TOKEN, loginResult.accessToken, {path: '/'});
    this.cookieService.set(this._REFRESH_TOKEN, loginResult.refreshToken, {path: '/'});
  }

  getAccessToken(): string {
    return this.cookieService.get(this._ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return this.cookieService.get(this._REFRESH_TOKEN);
  }

  removeAllToken(): void {
    this.cookieService.deleteAll('/');
  }

  hasAccessToken(): boolean {
    const hasAccessToken = this.getAccessToken();
    return hasAccessToken !== '';
  }

  secondsToExpire(): number {
    const accessToken = this.getAccessToken();
    const decoded: any = jwt_decode(accessToken);

    const exp = Number(decoded.exp);
    const strNow = new Date().getTime().toString();
    const now = Number((strNow.substring(0, strNow.length - 3)));

    return exp - now;
  }

  isTokenExpired(): boolean {
    if (!this.hasAccessToken()) {
      throw new Error('access token not found');
    }

    const exp = this.getAccessTokenExpireTime();
    const strNow = new Date().getTime().toString();
    const now = Number(strNow.substring(0, strNow.length - 3));
    return now > exp;
  }

  getAccessTokenExpireTime(): number {
    const accessToken = this.getAccessToken();
    const decoded: any = jwt_decode(accessToken);
    return Number(decoded.exp);
  }

  setIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated.next(isAuthenticated);
  }
}
