import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TokenService} from '../services/token.service';
import {map} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import {RoleType} from '../enums/role-type.enum';
import {AccessService} from '../services/access.service';
import {UtilityService} from '../services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class SystemGuard implements CanActivate {

  constructor(private tokenService: TokenService,
              private accessService: AccessService,
              private utilityService: UtilityService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.tokenService.isAuthenticated$.pipe(
      map(isAuth => {
        if (!isAuth) {
          this.router.navigate(['auth']).then();
          return false;
        }
        let token = new Object();
        token = jwt_decode(this.tokenService.getAccessToken());
        // @ts-ignore
        const roleValue = token.hasOwnProperty('http://schemas.microsoft.com/ws/2008/06/identity/claims/role') ? token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : 'Employee';
        this.accessService.roleType = this.utilityService.roleList.indexOf(roleValue) + 1;
        this.accessService.hasModifyOrConfirmAccess = token.hasOwnProperty('ModifyOrConfirmVacations');
        return true;
      })
    );
  }
}
