import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResizeService} from '../shared/services/resize.service';
import {MainService} from './main.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import * as signalR from '@aspnet/signalr';
import {ActivatedRoute, Router} from '@angular/router';
import {AccessService} from '../shared/services/access.service';
import {TokenService} from '../shared/services/token.service';
import {ConfigService} from '../shared/services/config.service';
import {ToastifyService} from '../shared/services/toastify.service';
import {NotificationAlertComponent} from '../shared/components/notification-alert/notification-alert.component';

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  url = '';
  private hubConnection!: signalR.HubConnection;
  private connectionId = '';

  constructor(private resizeService: ResizeService,
              private router: Router,
              private mainService: MainService,
              private activatedRoute: ActivatedRoute,
              private tokenService: TokenService,
              private configService: ConfigService,
              private toastifyService: ToastifyService,
              private accessService: AccessService) {

    this.activatedRoute.data
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        accessService.bootstrapperData = res.mainInfo.result;
        this.mainService.hasNotification.next(res.mainInfo.result.hasUnreadNotification);
        this.url = `${this.configService.BaseUrl}/notification?department_Id=${res.mainInfo.result.departmentId}&access_token=${this.tokenService.getAccessToken()}`;
      });
  }

  ngOnInit(): void {
    this.resizeService.deviceMode
      .pipe(untilDestroyed(this))
      .subscribe(m => {
        if (m !== 'xs' && m !== 'sm') {
          this.mainService.menuHandler(false);
        }
      });
    this.joinConnection();
  }

  private joinConnection(): void {
    // @ts-ignore
    if (this.hubConnection && this.hubConnection.state === 1) {
      return;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.url)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.onConnectionStarted();
        this.onNewMessageGot();
      })
      .catch((err: any) => console.log('Error while starting connection: ' + err));
  }

  private onConnectionStarted(): void {
    this.hubConnection.on('SendConnectionId', (data: string) => {
      this.connectionId = data;
    });
  }

  private onNewMessageGot(): void {
    this.hubConnection.on('NotificationAdded', (data: { title: string, content: string, type: number | null }) => {
      this.mainService.hasNotification.next(true);
      this.toastifyService.show(data.title, NotificationAlertComponent, {
        toastClass: 'notifications-toast',
        positionClass: 'toast-top-left',
        timeOut: 4000,
      })
        .onTap
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['notifications']).then();
        });
    });

  }

  private leave(): void {
    this.hubConnection.stop()
      .then(() => {
        console.log('Goodbye ...');
      });
  }

  ngOnDestroy(): void {
    this.leave();
  }
}
