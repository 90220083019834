<div class="page404">
  <div class="page404Detail">
    <div class="page404txt">
      <h3>
        صفحه مورد نظر یافت نشد.
      </h3>
      <h1>
        404
      </h1>
      <a [routerLink]="['/']">
        بازگشت
      </a>
    </div>
    <div class="page404Img">
      <img src="assets/images/404.png" alt="404">
    </div>
  </div>
</div>
