import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @Input() label = 'جستجو';
  @Output() phraseChanged: EventEmitter<string> = new EventEmitter<string>();
  phrase: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $phrase: Observable<string>;
  constructor() {
    this.$phrase = this.phrase.asObservable();
  }
  ngOnInit(): void {
    this.$phrase
      .pipe(debounceTime(1000))
      .subscribe(x => {
        this.phraseChanged.emit(x);
      });
  }
  textChanged(e: any): void {
    this.phrase.next(e.target.value);
  }
  activeInput(wrap: any): void{
    wrap.classList.add('active');
  }
  deActiveInput(wrap: any): void{
    wrap.classList.remove('active');
  }
}
