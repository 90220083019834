import {Pipe, PipeTransform} from '@angular/core';
import {MBTI} from '../enums/MBTI';

@Pipe({
  name: 'mBTI'
})
export class MBTIPipe implements PipeTransform {
  mbtiList = MBTI;

  transform(value: number): string {
    return this.mbtiList[value] ? this.mbtiList[value] : '';
  }

}
