import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  @Input() text!: string;
  @Output() animationEnd: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('errorTag', {static: false}) errorTag!: ElementRef;

  constructor(private host: ElementRef<HTMLElement>,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  setOutAnimation(): void {
    this.errorTag.nativeElement.style.animation = 'errorOut 0.3s';
  }

  animationDone(): void {
    this.animationEnd.emit();
  }

}
