import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {MonthRangePickerService} from './month-range-picker.service';
import {DateDto} from '../../../main/modules/vacations/models/date.dto';

@UntilDestroy()

@Component({
  selector: 'app-month-range-picker',
  templateUrl: './month-range-picker.component.html',
  styleUrls: ['./month-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MonthRangePickerComponent implements OnInit {
  currentDate$!: Observable<DateDto>;
  currentDate!: DateDto;

  @Output() gotoMonthEmitter: EventEmitter<DateDto> = new EventEmitter<DateDto>();
  currentMonth!: number;

  constructor(private monthRangePickerService: MonthRangePickerService) {
    // TODO ???
    this.currentDate$ = this.monthRangePickerService.whichDate$;
  }

  ngOnInit(): void {
    this.monthRangePickerService.whichDate$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.currentDate = res;
      });
  }

  gotoMonth(month: number, status: string): void {
    let m: number;
    const dateTarget = this.currentDate;

    m = (status === 'next') ? month + 1 : month - 1;
    if (m > 0 && m <= 12) {
      dateTarget.month = m;
      dateTarget.year = this.currentDate.year;
    }
    if (m === 0) {
      dateTarget.month = 12;
      dateTarget.year = this.currentDate.year - 1;
    }
    if (m === 13) {
      dateTarget.month = 1;
      dateTarget.year = this.currentDate.year + 1;
    }
    this.gotoMonthEmitter.emit(dateTarget);
  }

}
