<div class="modal-header text-center modal-image">
  <img [src]="svg ? svg : utilityService.DeleteSVG" alt="">
</div>
<div class="modal-body text-center">
  <p class="title text-center">{{modalText}}</p>
</div>
<div class="modal-footer text-center mt-3">
  <div class="text-center" dir="rtl">
    <button class="btn btn-wide btn-red" (click)="onSubmit()">
      {{modalConfirmButton ? modalConfirmButton : 'حذف'}}
    </button>
    <button class="btn btn-wide btn-gray-fill mr-2" type="reset"
            (click)="ngbActiveModal.dismiss()">{{modalCancelButton ? modalCancelButton : 'بازگشت' }}</button>
  </div>
</div>
