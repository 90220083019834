import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input, OnDestroy, OnInit,
  Output, Renderer2,
  Self, ViewContainerRef
} from '@angular/core';
import {FormErrorComponent} from '../components/form-error/form-error.component';
import {NgControl} from '@angular/forms';
import {FORM_ERRORS} from '../services/error-handler';
import {FromErrorService} from '../services/from-error.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appFormError]'
})
export class FormErrorDirective implements OnInit, OnDestroy {
  text!: string;
  compRef!: ComponentRef<FormErrorComponent> | undefined;
  @Input() autoGeneratedError = true;
  @Input() appendedInput = false;
  @Output() errorStatusChanged: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Input() moreCheckRequired = false;


  constructor(@Self() private control: NgControl,
              @Inject(FORM_ERRORS) private errors: any,
              private elementRef: ElementRef,
              private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private renderer2: Renderer2,
              private formErrorService: FromErrorService,
              private vcr: ViewContainerRef) {
  }

  removeError(): void {
    if (this.compRef) {
      this.compRef.instance.setOutAnimation();
      this.compRef.instance.animationEnd
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.compRef) {
            this.compRef.destroy();
            this.compRef = undefined;
          }
        });
    }
  }

  setError(text: string): void {
    if (this.autoGeneratedError) {
      if (!this.compRef) {
        const factory = this.resolver.resolveComponentFactory(FormErrorComponent);
        this.compRef = this.vcr.createComponent(factory);
        const loaderComponentElement = this.compRef.location.nativeElement;
        let targetElement: HTMLElement;
        if (this.appendedInput) {
          targetElement = loaderComponentElement.parentNode.parentNode;
        } else {
          targetElement = loaderComponentElement.parentNode;
        }
        targetElement.insertAdjacentElement('beforeend', loaderComponentElement);
      }
      this.compRef.instance.text = text;
    } else {
      // TODO: IS IT NEEDED?
      // if (!this.compRef) {
      //   const factory = this.resolver.resolveComponentFactory(ErrorComponent);
      //   this.compRef = this.vcr.createComponent(factory);
      // }
      // this.compRef.instance.text = text;
    }
  }

  setErrorStyleForInput(): void {
    this.renderer2.addClass(this.elementRef.nativeElement, 'input-error');
  }

  removeErrorStyleForInput(): void {
    this.renderer2.removeClass(this.elementRef.nativeElement, 'input-error');
  }

  ngOnInit(): void {
    if (this.moreCheckRequired) {
      // @ts-ignore
      this.control.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          const controlErrors = this.control.errors;
          if (controlErrors) {
            const firstKey = Object.keys(controlErrors)[0];
            const getError = this.errors[firstKey];
            const text = getError(controlErrors[firstKey]);
            this.formInvalid(text);
          } else if (this.compRef) {
            this.formValid();
          } else {
            this.formValid();
          }
        });
    }


    // @ts-ignore
    this.control.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      const controlErrors = this.control.errors;
      if (controlErrors) {
        const firstKey = Object.keys(controlErrors)[0];
        const getError = this.errors[firstKey];
        const text = getError(controlErrors[firstKey]);
        this.formInvalid(text);
      } else if (this.compRef) {
        this.formValid();
      } else {
        this.formValid();
      }
    });
  }

  ngOnDestroy(): void {
  }

  private formInvalid(text: any): void {
    this.errorStatusChanged.emit(text);
    this.setErrorStyleForInput();
    this.setError(text);
  }

  private formValid(): void {
    this.errorStatusChanged.emit(null);
    this.removeErrorStyleForInput();
    this.removeError();
  }

}
