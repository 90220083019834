import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private buttonLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  buttonLoading$: Observable<boolean> = this.buttonLoading.asObservable();

  constructor() {
  }

  setButtonLoading(event: boolean): void {
    this.buttonLoading.next(event);
  }
}
