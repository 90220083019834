<div class="checkbox-wrapper">
  <input
    [indeterminate]="indeterminate"
    [checked]="checked"
    (change)="changeHandler($event)"
    type="checkbox"
    class="checkbox-input"
    id="{{idName}}"
  >
  <label class="checkbox-label" for="{{idName}}">
    <span class="checkbox-label-circle">
      <app-svg-icon icon="icon-check" class="icon"></app-svg-icon>
    </span>
    <span class="checkbox-label-text">{{label ? label : ''}}</span>
  </label>
</div>
