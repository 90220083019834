import {Injectable} from '@angular/core';
import { Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {ResponseModel} from '../models/response-model';
import {Bootstrapper} from '../models/bootstrapper';
import {MainService} from '../../main/main.service';
import {map} from 'rxjs/operators';
import {AccessService} from './access.service';

@Injectable({
  providedIn: 'root'
})
export class MainResolver implements Resolve<ResponseModel<Bootstrapper>> {
  constructor(private mainService: MainService,
              private accessService: AccessService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResponseModel<Bootstrapper>> {
    return this.mainService.getBootstrapperData()
      .pipe(
        map((response) => {
          this.accessService.claimList = response.result.claims;
          this.accessService.userId = response.result.userId;
          this.accessService.departmentId = response.result.departmentId;
          // this.accessService.roleType = response.result.role;
          return response;
        })
      );
  }
}
