import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {EmergencyReasonEnum} from '../enums/emergency-reason.enum';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'emergencyReason'
})

export class EmergencyReasonPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case EmergencyReasonEnum.DelayedInRegistration:
        return 'به دلیل تاخیر در اعلام مرخصی، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
      case EmergencyReasonEnum.SelectedAsReplacement:
        return 'به دلیل جایگزین بودن در زمان مرخصی، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
        case EmergencyReasonEnum.DelayedAndReplacement:
        return 'به دلیل تاخیر در اعلام مرخصی و همچنین به دلیل جایگزین بودن در زمان مرخصی، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
      case EmergencyReasonEnum.HourlyVacationInMonth:
        return 'به دلیل گذشتن از سقف مرخصی‌های ساعتی این ماه، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
      case EmergencyReasonEnum.LongConsecutiveDays:
        return 'به دلیل گذشتن از سقف تعداد مرخصی روزانه در یک درخواست، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
      case EmergencyReasonEnum.FrequentLongConsecutiveDays:
        return 'به دلیل گذشتن از سقف مرخصی‌های 6 ماه گذشته، این مرخصی جزو مرخصی‌های اضطراری محسوب می‌شود.';
      default:
        return 'این مرخصی به دلایلی جز مرخصی های اضطراری محسوب می‌شود';
    }
  }
}
// مرخصی‌های روزانه این ماه
