import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {ResponseModel} from '../shared/models/response-model';
import {Bootstrapper} from '../shared/models/bootstrapper';
import {ApiService} from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  menuToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  menuToggle$: Observable<boolean> = this.menuToggle.asObservable();

  hasNotification: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  hasNotification$: Observable<boolean> = this.hasNotification.asObservable();
  constructor(private apiService: ApiService) {
  }

  getBootstrapperData(): Observable<ResponseModel<Bootstrapper>> {
    const url = 'Bootstrapper';
    return this.apiService.get(url, true);
  }

  menuHandler(event: boolean): void {
    this.menuToggle.next(event);
  }
}
