import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFormComponent implements OnInit {

  @Input() idName: any;
  @Input() label!: string;
  @Input() checked!: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() indeterminate!: boolean;

  constructor() {
  }

  ngOnInit(): void {

  }

  changeHandler(event: any): void {
    this.checkedChange.emit(event.target.checked);
  }
}
