<div class="form-items"
     [ngClass]="{'ltr-input': isLtr}">
  <div class="form-items-input">
<!--    <input *ngIf="isNumeric" [value]="value"-->
<!--           [disabled]="disabled"-->
<!--           [placeholder]="placeholderText"-->
<!--           [type]="type?'password':'text'"-->
<!--           autocomplete="off"-->
<!--           (input)="changeInput($event)"-->
<!--           (blur)="onTouched()"-->
<!--           [id]="inputId"-->
<!--           [maxLength]="maxlength ? maxlength : 524288"-->
<!--           [minLength]="minlength"-->
<!--           [pattern]="pattern ? pattern : null"-->
<!--           appNumericOnly-->
<!--           [ngClass]="{'input-dirty' : isChange}"/>-->

    <input [value]="value"
           [disabled]="disabled"
           [placeholder]="placeholderText"
           [type]="type?'password':'text'"
           autocomplete="off"
           (input)="changeInput($event)"
           (blur)="onTouched()"
           [id]="inputId"
           appAutoFocus
           [focusBar]="autoFocus"
           [maxLength]="maxlength ? maxlength : 524288"
           [minLength]="minlength"
           [pattern]="pattern ? pattern : null"
           [ngClass]="{'input-dirty' : isChange}"/>

    <label *ngIf="inputId && labelText" [for]="inputId">{{labelText}}</label>
    <button type="button" *ngIf="isPassword" (click)="passwordHandler()">
      <svg *ngIf="type" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="20"
           height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
           stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="2"/>
        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
      </svg>
      <svg *ngIf="!type" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="20"
           height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
           stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <line x1="3" y1="3" x2="21" y2="21"/>
        <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"/>
        <path
          d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"/>
      </svg>
    </button>
    <span class="active-border"></span>
  </div>
</div>
