import {Component, DoCheck, forwardRef, Input, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
})

export class InputFormComponent implements ControlValueAccessor, OnInit, DoCheck {
  @Input() disabled!: boolean;
  @Input() isLtr = false;
  @Input() labelText = '';
  @Input() data!: string;
  @Input() placeholderText = '';
  @Input() inputId = '';
  @Input() isPassword = false;
  @Input() maxlength = '';
  @Input() minlength = '';
  @Input() pattern = '';
  @Input() autoFocus = false;
  @Input() isNumeric!: boolean;

  value: any = '';

  type = false;

  isChange = false;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.value = this.data ? this.data : '';
    this.isChange = this.value && this.value.length > 0;
    this.type = this.isPassword;
  }

  ngDoCheck(): void {
    this.isChange = this.value && this.value.length > 0;
  }

  passwordHandler(): void {
    this.type = !this.type;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeInput(e: any): void {
    this.onChange(e.target.value);
    this.value = e.target.value;
    this.isChange = e.target.value.length > 0;
  }

  onChange = (_: any) => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }


  onTouched = () => {
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
