<div class="filter-item" [class]="{'active' : showSubMenu , 'activeFilter' : activeFilter}">
  <div class="filter-result" (click)="toggleShowSubMenu()">
    <span>
      بازه زمانی
    </span>
    <span style="margin: 0 3px">
      :
    </span>
    <span style="margin-left: 7px">
      <span> {{fromDate ? parseIntFunc(fromDate.substr(fromDate.length - 2)) : "ابتدا"}}</span>
       تا
      <span> {{toDate ? parseIntFunc(toDate.substr(toDate.length - 2)) : "انتهای"}}</span>
      {{currentMonth | months}}
    </span>
    <span class="mr-auto">
      <app-svg-icon icon="icon-arrowDown" class="icon"></app-svg-icon>
    </span>
  </div>
  <ul class="filter-option" *ngIf="showSubMenu">
    <li class="dateFilterOption">
      <app-date-time
        [dateTime]="fromDate"
        [config]="dateTimeConfig"
        [minDate]="minFromDate"
        [maxDate]="maxFromDate"
        [texts]="{datePl:'', dateLabel:'شروع بازه زمانی'}"
        [isCancelBtn]="false"
        (dateTimeChange)="patchFromDatePickerValue($event)">
      </app-date-time>
    </li>
    <li class="dateFilterOption">
      <app-date-time
        [dateTime]="toDate"
        [config]="dateTimeConfig"
        [minDate]="minToDate"
        [maxDate]="maxToDate"
        [texts]="{datePl:'', dateLabel:'پایان بازه زمانی'}"
        [isCancelBtn]="false"
        (dateTimeChange)="patchToDatePickerValue($event)">
      </app-date-time>
    </li>
    <li class="filterDateButton">
      <button class="deleteDateFilter" [disabled]="!activeFilter" [class]="activeFilter ? 'active' : null"
              (click)="deleteDateFilter()">
        حذف فیلتر
      </button>
      <button class="submitDateFilter" [disabled]="!(this.fromDate && this.toDate)"
              [class]="(this.fromDate && this.toDate)  ? 'active': null" (click)="submitFilterDate()">
        اعمال فیلتر
      </button>
    </li>
  </ul>
</div>

