import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {RouterModule} from '@angular/router';

import {FormErrorComponent} from './components/form-error/form-error.component';
import {FormErrorDirective} from './directives/form-error.directive';
import {InputFormComponent} from './components/input-form/input-form.component';
import {SvgIconComponent} from './components/svg-icon/svg-icon.component';
import {TimeCountComponent} from './components/time-count/time-count.component';
import {FormatTimePipe} from './pipes/format-time.pipe';
import {SafeSanitizingPipe} from './pipes/safe-sanitizing.pipe';
import {TimeConvertPipe} from './pipes/time-convert.pipe';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TimeCounterComponent} from './components/time-counter/time-counter.component';
import {MonthsPipe} from './pipes/months.pipe';
import {CheckboxFormComponent} from './components/checkbox-form/checkbox-form.component';
import {WeekdayPipe} from './pipes/weekday.pipe';
import {NumericOnlyDirective} from './directives/numeric-only.directive';
import {ShowMoreComponent} from './components/show-more/show-more.component';
import {OneInputModalComponent} from './components/one-input-modal/one-input-modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SearchComponent} from './components/search/search.component';
import {SelectFormComponent} from './components/select-form/select-form.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {CalendarComponent} from './components/calendar/calendar.component';
import {MonthRangePickerComponent} from './components/month-range-picker/month-range-picker.component';
import {VacationItemComponent} from './components/vacation-item/vacation-item.component';
import {VacationStatePipe} from './pipes/vacation-state.pipe';
import {VacationStatusPipe} from './pipes/vacation-status.pipe';
import {EmergencyReasonPipe} from './pipes/emergency-reason.pipe';
import {ConvertDateToStringPipe} from './pipes/convert-date-to-string.pipe';
import {TableComponent} from './components/table/table.component';
import {HistoryBarChartComponent} from './components/history-bar-chart/history-bar-chart.component';
import {VacationBarComponent} from './components/vacation-bar/vacation-bar.component';
import {VacationBarInfoComponent} from './components/vacation-bar-info/vacation-bar-info.component';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { FilterTableComponent } from './components/table/filter-table/filter-table.component';
import { FilterTableItemComponent } from './components/table/filter-table/filter-table-item/filter-table-item.component';
import { FilterTableItemDateComponent } from './components/table/filter-table/filter-table-item-date/filter-table-item-date.component';
import {CalendarModule} from './modules/calendar/calendar.module';
import { ButtonComponent } from './components/button/button.component';

const sharedDeclarations = [
  PageTitleComponent,
  FormErrorComponent,
  FormErrorDirective,
  InputFormComponent,
  TimeCountComponent,
  SafeSanitizingPipe,
  SvgIconComponent,
  FormatTimePipe,
  TimeConvertPipe,
  ToggleSwitchComponent,
  TimeCounterComponent,
  MonthsPipe,
  CheckboxFormComponent,
  WeekdayPipe,
  NumericOnlyDirective,
  OneInputModalComponent,
  ShowMoreComponent,
  SearchComponent,
  CalendarComponent,
  SelectFormComponent,
  MonthRangePickerComponent,
  VacationItemComponent,
  VacationStatePipe,
  VacationStatusPipe,
  EmergencyReasonPipe,
  ConvertDateToStringPipe,
  TableComponent,
  HistoryBarChartComponent,
  VacationBarComponent,
  VacationBarInfoComponent,
  DeleteModalComponent,
  AutoFocusDirective,
  NotificationAlertComponent,
  FilterTableComponent,
  FilterTableItemComponent,
  FilterTableItemDateComponent,
];

@NgModule({

  declarations: [
    ...sharedDeclarations,
    ButtonComponent
  ],
  imports: [
    ClickOutsideModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CalendarModule,
  ],
    exports: [
        ...sharedDeclarations,
        ButtonComponent,
    ],

  providers: [],
})
export class SharedModule {
}
