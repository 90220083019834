import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateTimeComponent} from './component/date-time/date-time.component';
import {NgbDatepickerModule, NgbDropdownModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared.module';



@NgModule({
  declarations: [
    DateTimeComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    FormsModule,
    NgbTimepickerModule,
  ],
  exports: [
    DateTimeComponent
  ]
})
export class CalendarModule { }
