import {Component, Input, OnInit} from '@angular/core';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() setClass = 'btn';
  @Input() disabled?: any;

  constructor(public loadingService: LoadingService) {
  }

  ngOnInit(): void {
  }

}
