import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastifyService} from './toastify.service';
import {AppNotifications} from '../enums/app-notifications.enum';
import {ServerErrors} from '../enums/server-errors.enum';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  private readonly refreshToken: string;
  private Unauthorized!: boolean;
  private Forbidden!: boolean;

  constructor(private router: Router,
              private toastifyService: ToastifyService,
              private tokenService: TokenService) {

    this.refreshToken = this.tokenService.getRefreshToken();
  }

  handle(error: any): void {

    let errorCode;
    let errorType;
    let authError;


    if (error && error.status) {
      authError = error.status === 401 || error.status === 403;
      this.Unauthorized = error.status === 401;
      this.Forbidden = error.status === 403;
    } else {
      errorType = 'knownError';
      errorCode = error;
    }

    if (authError) {
      errorType = 'auth';
    } else {
      if (error.error) {
        if (error.error.ErrorCode) {
          errorType = 'knownError';
          errorCode = error.error.ErrorCode;
        } else if (error.error.title) {
          errorType = 'error';
          errorCode = null;
        } else {
          errorType = 'unknownError';
          errorCode = 0;
        }
      }
    }

    switch (errorType) {
      case 'knownError':
        // @ts-ignore
        this.toastifyService.error(ServerErrors[errorCode] && AppNotifications[ServerErrors[errorCode]] ? AppNotifications[ServerErrors[errorCode]] : errorCode);
        break;
      case 'error':
        this.toastifyService.error(error.error.title);
        break;
      case 'auth':
        this.authHandler();
        // this.toastifyService.error(AppNotifications.LoginAgain);
        break;
      case 'unknownError':
        this.toastifyService.error(AppNotifications.UnknownError);
        break;
    }
  }

  authHandler(): void {
    if (this.Unauthorized && this.refreshToken) {
      // this.authService.refreshToken();
    } else {
      this.toastifyService.error(AppNotifications.LoginAgain);
    }
    if (this.Forbidden) {
      // this.tokenService.removeAllToken();
      this.toastifyService.error(AppNotifications.LoginAgain);
    }
  }
}
