import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() focusBar!: boolean;

  constructor(private host: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.focusBar) {
      this.host.nativeElement.focus();
    }
  }

}
