<div class="custom-modal">
  <form #form="ngForm" (ngSubmit)="onSubmit(form)">
    <div class="modal-header">
      <p>
        <app-svg-icon icon="icon-writing" class="icon-size-24"></app-svg-icon>
        <span> لطفا دلیل رد کردن مرخصی را بنویسید.</span>
      </p>
    </div>
    <div class="modal-body">
      <label>
        <textarea placeholder="" name="rejectionReason" [(ngModel)]="rejectionReason" required></textarea>
      </label>
    </div>

    <div class="modal-footer">
      <div class="d-flex align-items-center justify-content-center mt-4">
        <button class="btn btn-red" type="submit" [disabled]="!form.form.valid" >رد مرخصی</button>
        <button class="btn btn-dark-gray" type="reset" (click)="activeModal.dismiss('Cross Click')">بازگشت</button>
      </div>
    </div>
  </form>
</div>
