<div class="vac-cart">
  <span class="vac-type" [ngClass]="vacationItem.type ===  VacationType.Hourly ? 'hourly' : 'daily'">
    <app-svg-icon *ngIf="vacationItem.type ===  VacationType.Hourly"
                  icon="icon-time"
                  class="icon-size-33"
    ></app-svg-icon>
    <app-svg-icon *ngIf="vacationItem.type ===  VacationType.Daily"
                  icon="icon-sun"
                  class="icon-size-33"
    ></app-svg-icon>
  </span>

  <div class="modify-vacation" *ngIf="userRoleId.role === UserRole.Hr && !userVacationState">
    <button class="btn btn-green" (click)="ModifyStatusVacation(vacationItem.id,true)">تایید</button>
    <button class="btn btn-red" (click)="ModifyStatusVacation(vacationItem.id,false)">رد</button>
  </div>

  <p>
    <span>{{ (vacationItem.type == VacationType.Hourly) ? 'ساعتی' : 'روزانه'}}</span>
    <ng-container *ngIf="userVacationState">
      <span class="vac-state" [ngClass]="(userVacationState == VacationState.Done ? ' done-text' :
                     (userVacationState == VacationState.OnPerforming ? 'onPerforming-text' : 'waitingToDo-text')) ">
       {{userVacationState | vacationState }}
    </span>
    </ng-container>
  </p>

  <div class="user-info" *ngIf="userRoleId.role === UserRole.Hr">
    <span class="user-icon">
      <img src="{{vacationItem.imageUrl ? vacationItem.imageUrl : 'assets/images/user_default.png'}}" alt="">
    </span>
    <span>{{vacationItem.fullName}}</span>
  </div>

  <p class="fo-size-15">
    <ng-container *ngIf="vacationItem.type == VacationType.Hourly">
      {{vacationItem.to | slice:0:5}}_{{vacationItem.from | slice:0:5 }} &nbsp; | &nbsp; {{presentableDate}}
    </ng-container>
    <ng-container *ngIf="vacationItem.type == VacationType.Daily">
      {{presentableDate}}
    </ng-container>
  </p>

  <ng-container *ngIf="userRoleId.role === UserRole.User">
    <p class="vac-status" [ngClass]="(vacationItem.status == VacationStatus.Rejected ? ' reject-text' :
                                   (vacationItem.status == VacationStatus.Confirmed ? 'confirm-text' : 'pending-text')) "
    >
      {{vacationItem.status | vacationStatus}}
    </p>
  </ng-container>

  <div class="vac-action" *ngIf="userRoleId.role === UserRole.User && vacationItem.status!== VacationStatus.Confirmed">
    <app-svg-icon *ngIf="vacationItem.status== VacationStatus.Pending" icon="icon-trash" (click)="deletedVacationAsUser(vacationItem.id)"
                  class="icon-size-24 icon-trash"></app-svg-icon>
    <app-svg-icon *ngIf="vacationItem.status== VacationStatus.Rejected" icon="icon-info" (click)="showInfo(vacationItem)"
                  class="icon-size-24 icon-info"></app-svg-icon>
    <app-svg-icon *ngIf="vacationItem.status== VacationStatus.Pending" icon="icon-edit" (click)="modifyVacation(vacationItem)"
                  class="icon-size-24"></app-svg-icon>
  </div>

  <div class="vac-action" *ngIf="userRoleId.role === UserRole.Hr ">
<!--  *ngIf="userRoleId.role === UserRole.Hr && (vacationItem.status !== VacationStatus.Rejected && userVacationState == VacationState.Done || visibility) " -->
    <app-svg-icon *ngIf="vacationItem.status == VacationStatus.Confirmed && userVacationState == VacationState.Done"
                  (click)="deletedVacationAsHr(vacationItem.id)"
                  icon="icon-trash"
                  class="icon-size-24 icon-trash"></app-svg-icon>

    <app-svg-icon *ngIf="vacationItem.status !== VacationStatus.Rejected && visibility"
                  (click)="showInfo(vacationItem)"
                  icon="icon-info"
                  class="icon-size-24 icon-info"></app-svg-icon>

    <app-svg-icon *ngIf="vacationItem.status == VacationStatus.Confirmed && userVacationState == VacationState.Done"
                  (click)="modifyVacationAsSupervisor(vacationItem)"
                  icon="icon-edit"
                  class="icon-size-24"></app-svg-icon>
  </div>

</div>
