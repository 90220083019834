import { Pipe, PipeTransform } from '@angular/core';
import {MilitaryStatus} from '../enums/military-status';
import {EducationDegree} from '../enums/education-degree';

@Pipe({
  name: 'military'
})
export class MilitaryPipe implements PipeTransform {

  transform(value: MilitaryStatus): string {
    switch (value) {
      case MilitaryStatus.Exempt:
        return 'معاف';
      case MilitaryStatus.TemporaryExempt:
        return 'معاف موقت';
      case MilitaryStatus.HasCertificate:
        return 'پایان خدمت';
      case MilitaryStatus.Conscript:
        return 'سرباز';
      default:
        return '';
    }
  }

}
