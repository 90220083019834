<div class="btn btn-gray btn-years" *ngIf="{currentDate: currentDate$ | async} as O">
  <ng-container *ngIf="O.currentDate && O.currentDate.month">
    <app-svg-icon
      icon="icon-arrowRight"
      class="icon-size-11"
      (clickEmitter)="gotoMonth(O.currentDate.month, 'pre')"
    ></app-svg-icon>

    <span class="current-month">{{O.currentDate.month | months}} ماه {{O.currentDate.year}} </span>

    <!--[class.disabled]="O.currentMonth === 12"-->
    <app-svg-icon
      icon="icon-arrowLeft"
      class="icon-size-11"

      (clickEmitter)="gotoMonth(O.currentDate.month, 'next')"
    ></app-svg-icon>

  </ng-container>
</div>
