<form class="form-group" [formGroup]="modalForm" (submit)="onSubmit()">
  <div class="modal-header text-center">
    <img *ngIf="svg" [src]="svg">
    <h5 class="dark-gray-text">{{modalHeader}}</h5>
  </div>
  <div class="modal-body text-center">
    <p class="title text-center">{{modalText}}</p>
    <input [placeholder]="(inputConf && inputConf.placeholder) ? inputConf.placeholder : ''"
           [maxLength]="(inputConf && inputConf.maxLength) ? inputConf.maxLength : 128"
           class="form-control"
           [type]="(inputConf && inputConf.type) ? inputConf.type : 'text'" formControlName="text">
  </div>
  <hr>
  <div class="modal-footer text-center">
    <div class="text-center" dir="rtl">
      <button class="btn btn-sm w-100 btn-green" type="submit" [disabled]="!modalForm.valid">
        {{modalConfirmButton ? modalConfirmButton : 'بله'}}
      </button>
    </div>
    <div class="">
      <button class="btn btn-sm secondary-btn w-100 mt-1"
              (click)="ngbActiveModal.dismiss()">{{modalCancelButton ? modalCancelButton : 'فعلا نه!' }}</button>
    </div>
  </div>
</form>
