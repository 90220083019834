import {Pipe, PipeTransform} from '@angular/core';
import {EducationDegree} from '../enums/education-degree';

@Pipe({
  name: 'educationDegree'
})
export class EducationDegreePipe implements PipeTransform {

  transform(value: EducationDegree): string {
    switch (value) {
      case EducationDegree.UnderDiploma:
        return 'زیر دیپلم';
      case EducationDegree.Dimploma:
        return 'دیپلم';
      case EducationDegree.Associate:
        return 'کاردانی';
      case EducationDegree.Bachelor:
        return 'کارشناسی';
      case EducationDegree.Master:
        return 'کارشناسی ارشد';
      case EducationDegree.Doctoral:
        return 'دکتری';
      case EducationDegree.PostDoctoral:
        return ' فوق دکتری';
      default:
        return '';
    }
  }

}
