<div class="dt" [ngClass]="{'active': date && (date.day && date.month && date.year)}">
  <div class="date" [ngClass]="config.className ? config.className : 'w50'">
    <span class="date-label" *ngIf="config.labelLess !== true">{{texts ? texts.dateLabel : 'تاریخ'}}</span>
    <div class="w-100">

      <div ngbDropdown #datePickerDropDown="ngbDropdown">
        <div class="btn-box">
          <button type="button" class="" ngbDropdownToggle id="dateDropDown">
            <ng-container>
              <!--              <span class="date-placeholder"-->
              <!--                    *ngIf="!date || (!date.day || !date.month || !date.year)"> {{texts ? texts.datePl : ''}} </span>-->
              <span class="date-value" *ngIf="date && (date.day && date.month && date.year)">
                <span>{{date.year}}</span>/<span>{{date.month}}</span>/<span>{{date.day}}</span>
              </span>
            </ng-container>
          </button>
          <ng-container *ngIf="isCancelBtn">
            <button *ngIf="date && (date.day && date.month && date.year)" type="button" class="cancel-btn" (click)="clearDate($event)">
              <!--            <app-svg-icon icon="icon-cancel" class="icon"></app-svg-icon>-->
              <span>X</span>
            </button>
          </ng-container>
        </div>

        <div class="calendar-dropdown" ngbDropdownMenu aria-labelledby="dateDropDown">

          <ngb-datepicker
            dir="rtl"
            [ngModel]="date"
            [ngModelOptions]="{standalone: true}"
            (dateSelect)="dateChanged(datePickerDropDown, $event)"
            [minDate]="$minDate ? $minDate : {year:1300, month: 01, day: 01}"
            [maxDate]="$maxDate ? $maxDate : undefined"
            [firstDayOfWeek]="6"
          ></ngb-datepicker>
        </div>
      </div>

    </div>
    <span class="active-border"></span>
  </div>
  <div class="date-time-wrapper__time" *ngIf="config.hasTime">
    <label *ngIf="config.labelLess !== true">{{texts ? texts.timeLabel : 'زمان'}}</label>
    <ngb-timepicker [ngModelOptions]="{standalone: true}" [seconds]="config.hasSeconds" dir="ltr"
                    (change)="timeChanged()"
                    [(ngModel)]="time" [spinners]="false"></ngb-timepicker>
  </div>

</div>



