import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {DateTimeConfigInterface} from '../../../../modules/calendar/models/date-time-config.interface';
import {HistoryBarChartService} from '../../../history-bar-chart/service/history-bar-chart.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ResetVacationHistoryFilterService} from '../../../../services/reset-vacation-history-filter.service';
import * as moment from 'jalali-moment';

@UntilDestroy()
@Component({
  selector: 'app-filter-table-item-date',
  templateUrl: './filter-table-item-date.component.html',
  styleUrls: ['./filter-table-item-date.component.scss']
})
export class FilterTableItemDateComponent implements OnInit {
  @Output() selectedValue = new EventEmitter();
  currentMonth!: number;
  currentMonthStr!: string;
  showSubMenu = false;
  activeFilter = false;
  fromDate = '';
  toDate = '';
  dateTimeConfig: DateTimeConfigInterface = {
    hasTime: false,
    hasSeconds: false
  };
  minFromDate = '';
  maxFromDate = '';
  minToDate = '';
  maxToDate = '';
  private currentYearJalali: string;

  patchFromDatePickerValue(event: string): void {
    this.fromDate = event;
    this.minToDate = this.fromDate;
  }

  patchToDatePickerValue(event: string): void {
    this.toDate = event;
    this.maxFromDate = this.toDate;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showSubMenu = false;
      if (!this.activeFilter) {
        this.fromDate = '';
        this.toDate = '';
      }
    }
  }

  constructor(private elementRef: ElementRef,
              private historyService: HistoryBarChartService,
              private resetFilterService: ResetVacationHistoryFilterService  ) {
    this.currentYearJalali = moment().locale('fa').format('YYYY');
  }

  ngOnInit(): void {
    this.historyService.selectedMonth$
      .pipe(untilDestroyed(this))
      .subscribe(month => {
        this.currentMonth = month;
        this.currentMonthStr = month > 9 ? `${month}` : `0${month}`;
        this.minFromDate = `${this.currentYearJalali}${this.currentMonthStr}01`;
        this.maxFromDate = `${this.currentYearJalali}${this.currentMonthStr}31`;
        this.minToDate = `${this.currentYearJalali}${this.currentMonthStr}01`;
        this.maxToDate = `${this.currentYearJalali}${this.currentMonthStr}31`;
      });
    this.resetFilterService.resetFilter$
      .subscribe(restetStatus => {
        if (restetStatus){
          this.deleteDateFilter();
        }
      });
  }

  toggleShowSubMenu(): void {
    this.showSubMenu = !this.showSubMenu;
  }

  submitFilterDate(): void {
    if (this.fromDate && this.toDate) {
      this.selectedValue.emit({
        fromDate: this.fromDate,
        toDate: this.toDate
      });
      this.activeFilter = true;
      this.showSubMenu = false;
    }
  }

  deleteDateFilter(): void {
    if (this.fromDate && this.toDate && this.activeFilter) {
      this.fromDate = '';
      this.toDate = '';
      this.minFromDate = `${this.currentYearJalali}${this.currentMonthStr}01`;
      this.maxFromDate = `${this.currentYearJalali}${this.currentMonthStr}31`;
      this.minToDate = `${this.currentYearJalali}${this.currentMonthStr}01`;
      this.maxToDate = `${this.currentYearJalali}${this.currentMonthStr}31`;
      this.selectedValue.emit({
        fromDate: null,
        toDate: null
      });
      this.activeFilter = false;
      this.showSubMenu = false;
    }
  }

  parseIntFunc(str: string): number {
    return parseInt(str, 10);
  }
}
