<div class="time-counter-wrapper">
  <button [disabled]="!this.isIncrease" type="button" (click)="increaseHandler()">
    <app-svg-icon icon="icon-arrowUp" class="icon"></app-svg-icon>
  </button>
  <div class="time" dir="ltr"><span>{{time.hour < 10 ? '0' + time.hour : time.hour.toString()}}
    :{{time.minute < 10 ? '0' + time.minute : time.minute.toString()}}</span></div>
  <button [disabled]="!this.isDecrease" type="button" (click)="decreaseHandler()">
    <app-svg-icon icon="icon-arrowDown" class="icon"></app-svg-icon>
  </button>
</div>
