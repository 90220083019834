import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacation-bar-info',
  templateUrl: './vacation-bar-info.component.html',
  styleUrls: ['./vacation-bar-info.component.scss']
})
export class VacationBarInfoComponent implements OnInit {

  vacationConfig = [
    {
      className: 'gray',
      title: 'ساعات باقیمانده',
      alt: ' باقیمانده'
    },
    {
      className: 'green',
      title: 'ساعات دریافتی',
      alt: ' دریافتی'
    },
    {
      className: 'red',
      title: 'ساعات غیرمجاز',
      alt: 'غیرمجاز'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }


}
