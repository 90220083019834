import {Pipe, PipeTransform} from '@angular/core';
import {VacationStateEnum} from '../enums/vacation-state.enum';

@Pipe({
  name: 'vacationState'
})

export class VacationStatePipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case VacationStateEnum.Done:
        return 'انجام شده';
      case VacationStateEnum.OnPerforming:
        return 'در حال انجام';
      case VacationStateEnum.WaitingToDo:
        return 'در انتظار انجام';
      default:
        return 'نامشخص';
    }
  }
}
