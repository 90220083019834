import {Component, Injectable} from '@angular/core';
import {ComponentType, ToastrService} from 'ngx-toastr';
import {NotificationAlertComponent} from '../components/notification-alert/notification-alert.component';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastifyService {

  constructor(private toastService: ToastrService) {
  }

  success(message: string, title?: string, options?: object): void {
    this.toastService.success(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  error(message: string, title?: string, options?: object): void {
    this.toastService.error(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  warning(message: string, title?: string, options?: object): void {
    this.toastService.warning(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  info(message: string, title?: string, options?: object): void {
    this.toastService.info(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  show(message: string, component: ComponentType<any>, options?: any): any {
    const customOptions = options ? options : {};
    customOptions.toastComponent = component;
    return this.toastService.show(
      message,
      undefined,
      customOptions);
  }

}
