export enum ServerErrors {
  MobileNotRegistered = 1,
  WrongVerificationCode = 2,
  PasswordFormatIsInvalid = 3,
  UserNameOrPasswordIsWrong = 4,
  OldPasswordIsWrong = 5,
  MobileIsDuplicate = 6,
  NationalIdIsDuplicate = 7,
  AnotherActiveUserWithThisMobileIsExist = 8
}

