<!--<div class="calendar-grid" *ngIf="(calendarData$ | async)">-->
<div class="calendar-grid" *ngIf="(initialCalendarData | async)">

  <ul class="weekdays">
    <li *ngFor="let weekdayItem of weekdayItems">
      <abbr [title]="weekdayItem.abbreviation">{{weekdayItem.name}}</abbr>
    </li>
  </ul>

  <ul class="day-grid">
    <ng-container *ngFor="let item of calendar; let i = index;">
      <li [ngClass]="{ today: (today && item.day == today) && (currentMonth == monthRangePicker)}" [id]="item.day"
          (click)="selectedCell(item, $event)">
        <ng-container *ngIf="userRoleId.role == userRole.User">
            <span [ngClass]="item.vacationStatus == vacationStatusEnum.Pending ? 'pending'
                            : (item.vacationStatus == vacationStatusEnum.Confirmed ? 'confirm'
                            : (item.vacationStatus == vacationStatusEnum.Rejected ? 'reject'
                            : (item.holidaysEvent ? 'holiday' : '')))"
                  [title]="item.holidaysEvent ? item.holidaysEvent : ''"
            >
               {{item.day == 0 ? '' : item.day }}

              <ng-container *ngIf="item.vacationStatus">
                 <i class="mobile-vacation-status" [ngClass]="item.vacationStatus == vacationStatusEnum.Pending ? 'pending'
                              : (item.vacationStatus == vacationStatusEnum.Confirmed ? 'confirm'
                              : (item.vacationStatus == vacationStatusEnum.Rejected ? 'reject' : ''))"
                 ></i>
              </ng-container>
            </span>
          <ng-container *ngIf="item.vacationStatus" [ngSwitch]="item.vacationStatus">
            <app-svg-icon *ngSwitchCase="vacationStatusEnum.Pending" icon="icon-clock"
                          class="icon-size-24 event"></app-svg-icon>
            <app-svg-icon *ngSwitchCase="vacationStatusEnum.Confirmed" icon="icon-done"
                          class="icon-size-24 event"></app-svg-icon>
            <app-svg-icon *ngSwitchCase="vacationStatusEnum.Rejected" icon="icon-deny"
                          class="icon-size-24 event"></app-svg-icon>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="userRoleId.role == userRole.Hr">
           <span class="vacation-hr-mode" [ngClass]="item.holidaysEvent ? 'holiday' : ''">
              {{item.day == 0 ? '' : item.day }}

             <span class="icon-container">
               <ng-container *ngIf="item.dailyVacationCount">
                 <span [ngClass]="item.dailyVacationCount ? 'daily' : '' ">
                      {{item.dailyVacationCount }}
                   <app-svg-icon icon="icon-sun" class="icon-size-20"></app-svg-icon>
                 </span>
               </ng-container>

               <ng-container *ngIf="item.hourlyVacationCount">
                 <span [ngClass]="item.hourlyVacationCount ? 'hourly' : '' ">
                      {{item.hourlyVacationCount }}
                   <app-svg-icon icon="icon-time" class="icon-size-20"></app-svg-icon>
                 </span>
               </ng-container>
             </span>

           </span>
        </ng-container>

      </li>
    </ng-container>
  </ul>

</div>

