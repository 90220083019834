import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessService} from '../services/access.service';
import {RoleType} from '../enums/role-type.enum';
import {TokenService} from '../services/token.service';
import {ClaimType} from '../../main/modules/staff/enums/claims';

@Injectable({
  providedIn: 'root'
})
export class EmployeeInaccessibilityGuard implements CanActivate {
  roleType = RoleType;
  claimType = ClaimType;

  constructor(private accessService: AccessService,
              private tokenService: TokenService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.accessService.roleType !== this.roleType.Employee) {
      return true;
    }
    if (state.url.includes('team-management') && this.accessService.hasModifyOrConfirmAccess) {
      return true;
    }
    this.tokenService.removeAllToken();
    document.location.href = '/auth';
    return false;
  }

}
