<div class="filter-item" [class]="{'active' : showSubMenu , 'activeFilter' : activeFilter}">
  <div class="filter-result" (click)="toggleShowSubMenu()">
    <span>
      {{filterConfig.label}}
    </span>
    <span style="margin: 0 3px">
      :
    </span>
    <span style="margin-left: 7px">
      {{filterConfig.filterData[selectedItem].label}}
    </span>
    <span>
      <app-svg-icon icon="icon-arrowDown" class="icon"></app-svg-icon>
    </span>
  </div>
  <ul class="filter-option" *ngIf="showSubMenu">
    <li *ngFor="let filterItem of filterConfig.filterData;let i=index" (click)="changeFilterItem(i)">
      {{filterItem.label}}
    </li>
  </ul>
</div>

