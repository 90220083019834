import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {FormErrorComponent} from '../components/form-error/form-error.component';

@Injectable({
  providedIn: 'root'
})
export class FromErrorService {

  constructor(private resolver: ComponentFactoryResolver,
              private injector: Injector) {
  }

  createComponent(): ComponentRef<FormErrorComponent> {
    const factory = this.resolver.resolveComponentFactory(FormErrorComponent);
    return factory.create(this.injector);
  }
}
