export enum AppNotifications {
  MobileNotRegistered = 'این شماره همراه در سامانه ثبت نشده است.',
  WrongVerificationCode = 'کد وارد شده اشتباه است.',
  PasswordFormatIsInvalid = '.رمز عبور ایمن تری با توجه به ساختار تعیین شده، وارد کنید',
  UserNameOrPasswordIsWrong = 'رمز عبور یا نام کاربری اشتباه است.',
  OldPasswordIsWrong = 'رمزعبور قدیمی صحیح نیست.',
  MobileIsDuplicate = 'این شماره همراه در سامانه موجود است.',
  NationalIdIsDuplicate = 'کد ملی تکراری در سامانه موجود است.',
  UnknownError = 'خطایی در شبکه رخ داده است',
  LoginAgain = 'دوباره وارد شوید',
  AnotherActiveUserWithThisMobileIsExist = 'کاربر با این شماره همراه در شرکت دیگری فعال است.'
}
