import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {TableServiceService} from '../service/table-service.service';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit {
  constructor(private tableService: TableServiceService) {
  }
  filterConfig = [
    {
      label: 'حالت مرخصی',
      value: 'isEmergency',
      filterData: [
        {
          label: 'همه',
          value: null
        },
        {
          label: 'عادی',
          value: false
        },
        {
          label: 'اضطراری',
          value: true
        }
      ]
    },
    {
      label: 'نوع مرخصی',
      value: 'vacationType',
      filterData: [
        {
          label: 'همه',
          value: null
        },
        {
          label: 'ساعتی',
          value: 1
        },
        {
          label: 'روزانه',
          value: 2
        }
      ]
    }
  ];
  preFilter !: object;

  @Output() filteredData = new EventEmitter();

  ngOnInit(): void {
  }

  filteredItem(data: any): void {
    this.preFilter = {
      ...this.preFilter,
      ...data
    };
    // console.log(this.preFilter);
    this.tableService.filterInfo.next(this.preFilter);
  }

}
