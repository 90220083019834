export enum BloodType
{
    NegativeO = 1,
    PositiveO = 2,
    NegativeA = 3,
    PositiveA = 4,
    NegativeB = 5,
    PositiveB = 6,
    NegativeAB = 7,
    PositiveAB = 8
}
