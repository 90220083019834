import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SwitchLabelModel} from '../../models/switch-label.model';
import {Observable} from 'rxjs';
import {VacationsService} from '../../../main/modules/vacations/services/vacations.service';
import {ToggleSwitchService} from './toggle-switch.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleSwitchComponent implements OnInit {

  @Input() width!: number;
  @Input() switchLabel!: SwitchLabelModel;
  @Output() toggleSwitchStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  isSelected$!: Observable<boolean>;

  constructor(private vacationService: VacationsService,
              private toggleSwitchService: ToggleSwitchService) {
  }

  ngOnInit(): void {
    this.isSelected$ = this.toggleSwitchService.isSelectedToggleSwitch$;
  }

  setValue($event: any): void {
    // @ts-ignore
    this.toggleSwitchService.setToggleSwitchStatus($event.target.checked);
    this.toggleSwitchStatus.emit($event?.target.checked);
  }

}
