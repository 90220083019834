import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {NotFoundComponent} from './main/not-found/not-found.component';
import {SystemGuard} from './shared/guards/system.guard';
import {MainResolver} from './shared/services/main.resolver';
import {GetProfileResolver} from './main/modules/staff/services/get-profile.resolver';
import {StaffProfileComponent} from './main/modules/staff/components/staff-profile/staff-profile.component';
import {AuthGuard} from './main/auth/guards/auth.guard';
import {EmployeeInaccessibilityGuard} from './shared/guards/employee-inaccessibility.guard';
import {MaintenanceComponent} from './main/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    data: {title: 'ورود و ثبت نام'},
    loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [SystemGuard],
    resolve: {mainInfo: MainResolver},
    children: [
      {path: '', redirectTo: 'profile', pathMatch: 'full'},
      {
        path: 'vacations',
        loadChildren: () => import('./main/modules/vacations/vacations.module').then(m => m.VacationsModule)
      },
      {
        path: 'departments',
        canActivate: [EmployeeInaccessibilityGuard],
        loadChildren: () => import('./main/modules/departments/departments.module').then(m => m.DepartmentsModule)
      },
      {
        path: 'staff',
        canActivate: [EmployeeInaccessibilityGuard],
        loadChildren: () => import('./main/modules/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'team-management',
        canActivate: [EmployeeInaccessibilityGuard],
        loadChildren: () => import('./main/modules/team-management/team-management.module').then(m => m.TeamManagementModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./main/modules/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'profile',
        data: {title: 'پروفایل'},
        resolve: {profile: GetProfileResolver},
        component: StaffProfileComponent
      }
    ]
  },
  {
    path: '404',
    data: {title: 'صفحه مورد نظر یافت نشد'},
    component: NotFoundComponent
  },
  {
    path: 'maintenance',
    data: {title: 'در دست طراحی'},
    component: MaintenanceComponent
  },
  {
    path: '**',
    data: {title: 'صفحه مورد نظر یافت نشد'},
    redirectTo: '/404'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

