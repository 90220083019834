export enum monthsEnum {
  Farvardin = 1,
  Ordibehesht = 2,
  Khordad = 3,
  Tir = 4,
  Mordad = 5,
  Shahrivar = 6,
  Mehr = 7,
  Aban = 8,
  Azar = 9,
  Dey = 10,
  Bahman = 11,
  Esfand = 12
}
