<div class="page404">
  <div class="page404Detail">
    <div class="page404txt">
      <h3>
        این صفحه برای مدتی در دست تعمیر است، لطفا کمی بعد دوباره تلاش کنید، متشکریم.
      </h3>
      <a [routerLink]="['/']">
        بازگشت
      </a>
    </div>
    <div class="page404Img">
      <img src="assets/images/maintenance.png" alt="maintenance">
    </div>
  </div>
</div>
