import {Injectable} from '@angular/core';
import {ClaimType} from '../../main/modules/staff/enums/claims';
import {RoleType} from '../enums/role-type.enum';
import {TokenService} from './token.service';
import {Bootstrapper} from '../models/bootstrapper';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  claimList: ClaimType[] = [];
  roleType!: RoleType;
  userId!: string;
  roleList = RoleType;
  departmentId!: number;

  private _bootstrapperData!: Bootstrapper;
  hasModifyOrConfirmAccess!: boolean;


  constructor(private tokenService: TokenService) {
  }

  hasClaim(event: ClaimType[]): boolean {
    const hasAllClaim = event.every(elem => this.claimList.includes(elem));
    return hasAllClaim;
  }

  EmployeeInaccessibility(): void {
    if (this.roleType === this.roleList.Employee) {
      this.tokenService.removeAllToken();
      document.location.href = '/auth';
    }
  }

  get bootstrapperData(): Bootstrapper {
    return this._bootstrapperData;
  }

  set bootstrapperData(value: Bootstrapper) {
    this._bootstrapperData = value;
  }

}
