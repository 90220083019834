import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {VacationStatusEnum} from '../../enums/vacation-status.enum';
import {RefactorCalendarListDto} from '../../../main/modules/vacations/models/refactor-calendar-list.dto';
import {UserRoleDto} from '../../../main/modules/vacations/models/user-role.dto';
import {UserRoleEnum} from '../../enums/user-role.enum';
import {CalendarService} from './calendar.service';
import * as moment from 'jalali-moment';
import {MonthRangePickerService} from '../month-range-picker/month-range-picker.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarComponent implements OnInit {

  @Input() initialCalendarData!: Observable<RefactorCalendarListDto[]>;
  @Input() userRoleId!: UserRoleDto;
  @Input() today!: number;
  @Output() selectedDay: EventEmitter<{ item: RefactorCalendarListDto, $event: any }> = new EventEmitter<{ item: RefactorCalendarListDto, $event: any }>();

  calendarData$!: Observable<RefactorCalendarListDto[]>;
  calendar!: RefactorCalendarListDto[];
  vacationStatusEnum = VacationStatusEnum;
  userRole = UserRoleEnum;
  isSelected = false;
  activeItems!: RefactorCalendarListDto[];

  currentMonth: number;
  monthRangePicker!: number;

  weekdayItems = [
    {
      name: 'شنبه',
      abbreviation: ' ش'
    },
    {
      name: 'یکشنبه',
      abbreviation: ' ی'
    },
    {
      name: 'دوشنبه',
      abbreviation: ' د'
    },
    {
      name: 'سه شنبه',
      abbreviation: ' س'
    },
    {
      name: 'چهارشنبه',
      abbreviation: ' چ'
    },
    {
      name: 'پنج شنبه',
      abbreviation: ' پ'
    },
    {
      name: 'جمعه',
      abbreviation: ' ج'
    },
  ];

  constructor(private calendarService: CalendarService,
              private monthRangePickerService: MonthRangePickerService) {
    this.currentMonth = Number(moment().locale('fa').format('MM'));
    this.monthRangePickerService.whichDate$
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.monthRangePicker = res.month;
        }
      );
  }

  ngOnInit(): void {
    // this.calendarData$ = this.calendarService.calendarList$;
    // this.calendarData$.pipe(untilDestroyed(this))
    this.initialCalendarData.pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.changeOrderDays(res);
        }
      );
  }

  changeOrderDays(calendarData: RefactorCalendarListDto[]): void {
    // tslint:disable-next-line:variable-name
    let _calendarData: RefactorCalendarListDto[];
    let reOrderCalendarData: any[] = [];
    const startDay = calendarData[0].dayOfWeek;
    const typeMonth = calendarData.length;
    let emptyCellData;

    if (typeMonth === 31 && startDay === 6) {
      const element = calendarData.splice(29, 2);
      reOrderCalendarData = element.concat(reOrderCalendarData);

      emptyCellData = this.createDataForEmptyCell(startDay - 2);
      reOrderCalendarData = reOrderCalendarData.concat(emptyCellData);
    }

    if (typeMonth === 31 && startDay === 5) {
      const element = calendarData.pop();
      if (element instanceof RefactorCalendarListDto) {
        reOrderCalendarData.unshift(element);
      }
      emptyCellData = this.createDataForEmptyCell(startDay - 1);
      reOrderCalendarData = reOrderCalendarData.concat(emptyCellData);
    }

    if (typeMonth === 30 && startDay === 6) {
      const element = calendarData.pop();
      if (element instanceof RefactorCalendarListDto) {
        reOrderCalendarData.unshift(element);
      }
      emptyCellData = this.createDataForEmptyCell(startDay - 1);
      reOrderCalendarData = reOrderCalendarData.concat(emptyCellData);
    }

    if (startDay < 5 || (typeMonth === 30 && startDay === 5)) {
      emptyCellData = this.createDataForEmptyCell(startDay);
      reOrderCalendarData = reOrderCalendarData.concat(emptyCellData);
      for (let i = typeMonth + 1; i <= 35 - startDay; i++) {
        calendarData.push(new RefactorCalendarListDto(0, -1, '', null));
      }
    }

    _calendarData = reOrderCalendarData.concat(calendarData);

    // this.vacationService.setCalendarList(_calendarData);
    this.calendar = _calendarData;
  }

  createDataForEmptyCell(startDay: number): RefactorCalendarListDto[] {
    const days: RefactorCalendarListDto[] = [];
    for (let i = 0; i < startDay; i++) {
      days.push(new RefactorCalendarListDto(0, -1, '', null));
    }
    return days;
  }

  selectedCell(cell: RefactorCalendarListDto, event: any): void {
    if (cell.day > 0) {
      this.selectedDay.emit({item: cell, $event: event});
    }
  }
}

